import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createMember,
  deleteMember,
  getAllMembers,
  getMemberDetails,
  updateMemberStatus,
  updateMemberDetails,
  memberPaymentUpload,
  getAllReferralMembers,
  getMemberProfileDetails,
  updateMemberProfileDetails,
  getMemberClubIncomeTransactions,
} from '../api/member';

export const memberSlice = createSlice({
  name: 'member',
  initialState: {
    loading: false,
    error: {},
    // staff
    members: {},
    referralMembers: {},
    clubIncomeTransactions: {},
    memberDetails: {},
    payment: {},
    memberProfileDetails: {},
    memberProfileUpdateDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createMember.pending]: (state) => {
      state.loading = true;
    },
    [createMember.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.members;
      const newList = jsonState?.members;
      const modifiedList = {
        ...jsonState,
        members: Array.isArray(newList) ? [action.payload, ...newList] : [action.payload],
      };
      state.loading = false;
      state.members = modifiedList;
      state.error = {};
    },
    [createMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllMembers.pending]: (state) => {
      state.loading = true;
    },
    [getAllMembers.fulfilled]: (state, action) => {
      state.loading = false;
      state.members = action.payload;
      state.error = {};
    },
    [getAllMembers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllReferralMembers.pending]: (state) => {
      state.loading = true;
    },
    [getAllReferralMembers.fulfilled]: (state, action) => {
      state.loading = false;
      state.referralMembers = action.payload;
      state.error = {};
    },
    [getAllReferralMembers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getMemberClubIncomeTransactions.pending]: (state) => {
      state.loading = true;
    },
    [getMemberClubIncomeTransactions.fulfilled]: (state, action) => {
      state.loading = false;
      state.clubIncomeTransactions = action.payload;
      state.error = {};
    },
    [getMemberClubIncomeTransactions.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getMemberDetails.pending]: (state) => {
      state.loading = true;
    },
    [getMemberDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberDetails = action.payload;
      state.error = {};
    },
    [getMemberDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateMemberDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateMemberDetails.fulfilled]: (state, action) => {
      console.log('action?.payload =>', action?.payload);
      const jsonState = covertToJSON(state)?.members;
      console.log('jsonState =>', jsonState?.members);
      const modifiedList = {
        ...jsonState,
        members: jsonState.members?.map((i) =>
          i._id === action?.payload?._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.members = modifiedList;
      state.error = {};
    },
    [updateMemberDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateMemberStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateMemberStatus.fulfilled]: (state, action) => {
      console.log('action?.payload =>', action?.payload);
      const jsonState = covertToJSON(state)?.members;
      console.log('jsonState =>', jsonState?.members);
      const modifiedList = {
        ...jsonState,
        members: jsonState.members?.map((i) =>
          i._id === action?.payload?._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.members = modifiedList;
      state.error = {};
    },
    [updateMemberStatus.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteMember.pending]: (state) => {
      state.loading = true;
    },
    [deleteMember.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.members;
      const modifiedList = {
        ...jsonState,
        members: jsonState.members?.filter((member) => member._id !== action.payload),
      };
      state.loading = false;
      state.members = modifiedList;
      state.error = {};
    },
    [deleteMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    //  member payment
    [memberPaymentUpload.pending]: (state) => {
      state.loading = true;
    },
    [memberPaymentUpload.fulfilled]: (state, action) => {
      state.loading = false;
      state.payment = action.payload;
      state.error = {};
    },
    [memberPaymentUpload.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // member profile
    [getMemberProfileDetails.pending]: (state) => {
      state.loading = true;
    },
    [getMemberProfileDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberProfileDetails = action.payload;
      state.error = {};
    },
    [getMemberProfileDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // member profile update
    [updateMemberProfileDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateMemberProfileDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberProfileUpdateDetails = action.payload;
      state.memberProfileDetails = action.payload;
      state.error = {};
    },
    [updateMemberProfileDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = memberSlice.actions;

export default memberSlice.reducer;
