import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@mui/material/Unstable_Grid2';
import RefreshIcon from '@mui/icons-material/Refresh';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import StyleRoundedIcon from '@mui/icons-material/StyleRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';

import { paths } from 'src/routes/routes/paths';

import { getAllClubs } from 'src/server/api/club';
import { getSettingsDetails } from 'src/server/api/settings';

import AllClub from 'src/sections/clubIncomeTransaction/editClub';

import AdminDashboardWallet from './dashboard/Wallet';
import PartnerCheckInWidgets from './admin-check-in-widgets';
import AdminDashboardVerifiedList from './dashboard/UnverifiedMemberList';
import DashboardTransaction from './dashboard/Tables/CampaignTable/CampaignPage';
// ----------------------------------------------------------------------

const SPACING = 2;

export default function OverviewBookingView({ setSelectedYear, selectedYear }) {
  // const theme = useTheme();
  const dispatch = useDispatch();

  const { dashboardData, clubList, settingsData } = useSelector((state) => ({
    dashboardData: state.dashboard.dashboardData,
    loading: state.dashboard.loading,
    earningsChartData: state.dashboard.earningsChart,
    salesChart: state.dashboard.salesChart,
    clubList: state.club.clubs?.clubs,
    settingsData: state.settings.settingsDetails,
  }));

  useEffect(() => {
    dispatch(getAllClubs(dispatch));
  }, [dispatch]);

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);

  // const { totalSalesCount, totalSalesValue, totalEarningsValue, totalEarningsCount } =
  //   dashboardData;
  // const averageSales = totalSalesValue / totalSalesCount;

  // const averageEarnings = totalEarningsValue / totalEarningsCount;

  return (
    <Grid container spacing={SPACING} disableEqualOverflow>
      <Grid xs={12} md={12}>
        <PartnerCheckInWidgets
          chart={{
            series: [
              {
                label: 'Members',
                total: dashboardData?.membersCount?.allMembersCount || 0,
                img: '',
                link: paths.dashboard.member,
                unPaid: dashboardData?.membersCount?.unpaidMembersCount || 0,
                unVerified: dashboardData?.membersCount?.unverifiedMembersCount || 0,
                verified: dashboardData?.membersCount?.verifiedMembersCount || 0,
                iconDown: (
                  <TrendingDownRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#637381',
                    }}
                  />
                ),
                iconUp: (
                  <TrendingUpRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#22C55E',
                    }}
                  />
                ),
                iconDownRed: (
                  <TrendingDownRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#FF5630',
                    }}
                  />
                ),
              },
              {
                label: 'Clubs',
                total: dashboardData?.AllClubsCount || 5,
                link: paths.dashboard.club,
                img: '',
                clubMembers: dashboardData?.AllClubMembersCount || 0,
                activeMembers: dashboardData?.AllActiveClubMembersCount || 0,
                iconUsers: (
                  <GroupRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#637381',
                    }}
                  />
                ),
                iconUserSuccess: (
                  <PersonAddAlt1RoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#118D57',
                    }}
                  />
                ),
              },
              {
                label: 'Withdrawal Request',
                total: dashboardData?.withdrawalRequestsCount?.allWithdrawalRequestCount,
                img: '',
                link: paths.dashboard.withdrawal_requests,
                pending: dashboardData?.withdrawalRequestsCount?.pendingWithdrawalRequestCount || 0,
                approved:
                  dashboardData?.withdrawalRequestsCount?.approvedWithdrawalRequestCount || 0,
                cancelled:
                  dashboardData?.withdrawalRequestsCount?.cancelledWithdrawalRequestCount || 0,
                iconPending: (
                  <StyleRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#637381',
                    }}
                  />
                ),
                iconApproved: (
                  <VerifiedRoundedIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#118D57',
                    }}
                  />
                ),
                iconCancelled: (
                  <RefreshIcon
                    sx={{
                      height: 17,
                      width: 17,
                      color: '#B71D18',
                    }}
                  />
                ),
              },
            ],
          }}
        />
      </Grid>

      <Grid xs={8}>
        <AdminDashboardWallet settingsData={settingsData} />
      </Grid>
      <Grid xs={4}>
        <AllClub clubList={clubList} dashboard />
      </Grid>
      <Grid md={8}>
        <DashboardTransaction />
      </Grid>
      <Grid md={4}>
        <AdminDashboardVerifiedList />{' '}
      </Grid>
    </Grid>
  );
}

OverviewBookingView.propTypes = {
  setSelectedYear: PropTypes.any,
  selectedYear: PropTypes.any,
};
