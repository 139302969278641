import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { alpha, useTheme } from '@mui/material/styles';
import { Grid, Tooltip, Typography } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';

import { bgGradient } from 'src/theme/css';

// ----------------------------------------------------------------------

export default function MemberCover({
  username,
  email,
  avatarUrl,
  phone,
  coverUrl,
  verified,
  paid,
}) {
  const theme = useTheme();
  console.log(coverUrl);
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha('#0a0909cc', 0.8),

          imgUrl: coverUrl,
        }),
        height: 1,
        color: 'common.white',
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          left: { md: 24 },
          bottom: { md: 24 },
          zIndex: { md: 10 },
          pt: { xs: 6, md: 0 },
          position: { md: 'absolute' },
        }}
      >
        <Avatar
          alt={username}
          src={avatarUrl}
          sx={{
            mx: 'auto',
            width: { xs: 64, md: 128 },
            height: { xs: 64, md: 128 },
            border: `solid 2px ${theme.palette.common.white}`,
          }}
        >
          {username?.charAt(0).toUpperCase()}
        </Avatar>

        <Stack mt={4} px={2}>
          <Grid display="flex" alignItems="center">
            <Typography variant="h4">{username}</Typography>
            &nbsp;
            {verified === true && (
              <Tooltip title="Verified">
                {' '}
                <VerifiedRoundedIcon color="success" fontSize="large" />
              </Tooltip>
            )}
            {verified === false && paid === true && (
              <Tooltip title="Account unverified">
                {' '}
                <WarningRoundedIcon color="warning" fontSize="large" />
              </Tooltip>
            )}
            {paid === false && (
              <Tooltip title="Account Unpaid">
                {' '}
                <WarningRoundedIcon color="error" fontSize="large" />
              </Tooltip>
            )}
          </Grid>

          <Grid display="flex" alignItems="center" pt={1}>
            <MarkEmailUnreadRoundedIcon sx={{ fontSize: 12 }} fontSize="small" /> &nbsp;
            <Typography variant="body2">{email}</Typography>
          </Grid>
          <Grid display="flex" alignItems="center">
            <LocalPhoneRoundedIcon sx={{ fontSize: 12 }} fontSize="small" /> &nbsp;
            <Typography variant="body2">+91 &nbsp;{phone}</Typography>
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
}

MemberCover.propTypes = {
  avatarUrl: PropTypes.string,
  coverUrl: PropTypes.string,
  username: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  verified: PropTypes.bool,
  paid: PropTypes.bool,
};
