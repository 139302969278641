import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Card, CardMedia, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import copy from 'src/assets/other/bluecopy.png';
import walletAddress from 'src/assets/other/walletAddress.png';
import { deleteWalletAddress, getAllWalletAddress } from 'src/server/api/walletAddress';

import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';

import WalletAddressDialog from 'src/sections/walletAddress/walletAdressModal';

// ----------------------------------------------------------------------

export default function WalletAddressDetailsAdmin() {
  const dispatch = useDispatch();
  const confirm = useBoolean();
  const { enqueueSnackbar } = useSnackbar();

  const [openWalletAddressId, setOpenWalletAddressId] = useState({});
  const [openWalletAddressDialog, setOpenWalletAddressDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(); // State to hold the selected item data

  const { DataList } = useSelector((state) => ({
    loading: state.referrals.loading,
    MemberProfileDetails: state.member.memberProfileDetails,
    memberClubForMember: state.memberClub.memberClubsForMember?.memberClubs,
    DataList: state.walletAddress.walletAddresses.walletAddresses,
  }));
  console.log(DataList);

  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      // limit: storedRowsPerPage || table.rowsPerPage,
      limit: 5,
      dispatch,
    };
    dispatch(getAllWalletAddress(credentials));
  }, [dispatch]);

  const handleDelete = (item) => {
    setSelectedItem(item);
    confirm.onTrue();
  };
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  console.log(selectedItem);
  const handleDeleteConfirmClick = (id) => {
    console.log('deleteidi', id);

    const credentials = {
      dispatch,
      walletId: id,
    };
    console.log(id);

    dispatch(deleteWalletAddress(credentials));
    confirm.onFalse();
  };
  return (
    <>
      {openWalletAddressDialog && (
        <WalletAddressDialog
          isUpdate={openWalletAddressId}
          setOpenWalletAddressId={setOpenWalletAddressId}
          setOpen={setOpenWalletAddressDialog}
          open={openWalletAddressDialog}
        />
      )}
      <Card
        sx={{
          py: 3,
          minHeight: 300,
        }}
      >
        <Stack
          px={3}
          spacing={3}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-end', md: 'center' }}
          justifyContent="space-between"
        >
          <Typography variant="h6">Wallet Address</Typography>
          <Button
            type="submit"
            size="small"
            startIcon={<Iconify icon="mingcute:add-line" />}
            // onClick={setOpenWalletAddressDialog(true)}
            onClick={() => {
              setOpenWalletAddressDialog(true);
            }}
            sx={{ flexShrink: 0, color: '#00A76F' }}
          >
            Add Wallet
          </Button>
        </Stack>
        <Box
          sx={{
            pt: 2,
            px: 2,
            mx: 1,
            height: 230,
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            '&::-webkit-scrollbar': {
              width: '8px', // Width of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey', // Color of the scrollbar thumb
              borderRadius: '15px', // Border radius of the scrollbar thumb
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#F5F5F5', // Color of the scrollbar track
              borderRadius: '15px', // Border radius of the scrollbar track
            },
          }}
        >
          {DataList && DataList.length > 0 ? (
            <Stack spacing={1}>
              {(DataList || []).map((item, index) => (
                <Card
                  key={index}
                  sx={{
                    border: '1px solid #00B8D9 ',
                    borderRadius: '10px',
                    // boxShadow: 'box-shadow: 4px 4px 4px 0px #00000040',
                    boxShadow:
                      '6px 6px 2px 0 rgb(91 94 98 / 20%), 14px 16px 24px -4px rgb(120 127 133 / 12%)',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding={2}
                  >
                    <Typography>{item?.address}</Typography>
                    <Stack direction="row" spacing={1}>
                      <CopyToClipboard text={item?.address} onCopy={handleCopy}>
                        <Tooltip title="Copy Wallet">
                          <Button size="small" color="info" sx={{ minWidth: '27px' }}>
                            <CardMedia
                              component="img"
                              src={copy}
                              alt="copy"
                              sx={{ width: '20px' }}
                            />
                          </Button>
                        </Tooltip>
                      </CopyToClipboard>
                      <Tooltip title="Edit Wallet">
                        <Button
                          size="small"
                          color="info"
                          sx={{ minWidth: '27px' }}
                          onClick={() => {
                            setOpenWalletAddressId(item);
                            setOpenWalletAddressDialog(true);
                          }}
                        >
                          <Iconify icon="heroicons-outline:pencil-alt" sx={{ color: 'grey' }} />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Remove Wallet">
                        <Button
                          size="small"
                          color="info"
                          sx={{ minWidth: '27px' }}
                          onClick={() => {
                            handleDelete(item);
                          }}
                        >
                          <Iconify icon="heroicons-outline:trash" sx={{ color: 'grey' }} />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                </Card>
              ))}
            </Stack>
          ) : (
            <Stack flexDirection="row" spacing={2} alignItems="center">
              <Stack flex={1.5}>
                <CardMedia component="img" src={walletAddress} alt="copy" sx={{ width: '340px' }} />
              </Stack>
              <Stack gap={2} flex={2}>
                <Typography variant="caption" color="#919EAB">
                  You can easily transfer funds from your personal wallet to your account. This
                  ensures seamless transactions and keeps your funds easily accessible whenever you
                  need them.
                </Typography>
                <Typography variant="caption" color="#919EAB">
                  simply navigate to the &aops;Add New Wallet&aops; section. Once you add wallet,
                  you can transfer funds quickly and securely, giving you peace of mind and easy
                  access to your assets whenever you need them.
                </Typography>
              </Stack>
            </Stack>
          )}
        </Box>
      </Card>
      <ConfirmDialog
        title={`Are you sure want to delete  '${selectedItem ? selectedItem?.address : ''}' ? `}
        content="Are you sure you want to delete this Wallet Address? This action cannot be undone."
        action={
          <Button
            variant="contained"
            sx={{
              color: 'white',
              backgroundColor: '#106E58',
              '&:hover': {
                backgroundColor: '#0b4e3e',
                boxShadow: 'unset',
              },
            }}
            onClick={() => handleDeleteConfirmClick(selectedItem?._id)}
          >
            Delete
          </Button>
        }
        open={confirm.value}
        onClose={confirm.onFalse}
      />
    </>
  );
}
