import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Paper, TableRow, TableCell, ListItemText, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { fDate } from 'src/utils/format-time';

import { getAllMembers } from 'src/server/api/member';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import EmptyContent from 'src/components/empty-content/empty-content';
import { useTable, TableHeadCustom, TableSelectedAction } from 'src/components/table';

import CampaignTableRowMember from './CapaignTableRow';

// ----------------------------------------------------------------------

export default function DashboardCampaign({ values, date }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();
  const role = useGetRoles()?.role;

  const { loading } = useSelector((state) => ({
    loading: state.app.loading,
    // transactionList: state.transaction.transactions,
    membersList: state.member.members?.members,
  }));
  const identifier = 'transaction';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [tableData, setTableData] = useState([]);

  const TABLE_HEAD = [
    { id: 'campaign', label: 'Campaign' },
    { id: 'spend', label: 'Spend' },
    { id: 'sales', label: 'Sales' },
    { id: 'acos', label: 'ACoS' },
  ];

  useEffect(() => {
    if (role === 'admin') {
      // dispatch(getAllTransactionsAdmin(credentials));
    } else {
      // dispatch(getAllTransactions(credentials));
    }
  }, [dispatch, role, storedRowsPerPage, table.page, table.rowsPerPage]);

  // useEffect(() => {
  //   setTableData(transactionList?.walletTransactions);
  // }, [transactionList]);

  const dataInPage = [];

  // const canReset = false;

  // const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  // const HandlePage = () => {
  //   navigate(paths.dashboard.transaction, {});
  // };
  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      limit: '',
      sort: '',
      date: '',
    };
    if (role === 'admin') {
      dispatch(getAllMembers(credentials));
    }
  }, [role, dispatch]);

  console.log(table);

  console.log(values);

  return (
    <>
      <Stack>
        <Card>
          <Stack p={2}>
            <ListItemText
              primary="Highest ACoS Campaigns"
              secondary={`${fDate(date ? date[0]?.startDate : new Date())} - ${fDate(
                date ? date[0]?.endDate : new Date()
              )}`}
              primaryTypographyProps={{
                component: 'subtitle2',
                typography: 'subtitle2',
              }}
              secondaryTypographyProps={{
                typography: 'span',
                fontSize: 11,
              }}
            />
          </Stack>

          <TableContainer sx={{ position: 'relative', overflow: 'unset' , minHeight: 334}}>
            <TableSelectedAction
              dense
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              {values?.length !== 0 ? (
                <Table size={table.dense ? 'small' : 'medium'}  >
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    numSelected={table.selected?.length}
                    onSort={table.onSort}
                  />

                  {loading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <CircularProgress color="inherit" />
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                  <TableBody >
                    {(Array.isArray(values) ? values : []).map((row, index) => (
                      <CampaignTableRowMember
                        index={index}
                        page={table.page + 1}
                        rowsPerPage={storedRowsPerPage || table.rowsPerPage || 5}
                        key={row.id}
                        row={row}
                        selected={table?.selected?.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        loading={loading}
                        colSpan={TABLE_HEAD?.length}
                      />
                    ))}
                  </TableBody>

                  {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}
                </Table>
              ) : (
                // <TableNoData notFound={notFound} />

                <Stack sx={{ minHeight: 334, maxHeight: 500 }}>
                  <EmptyContent
                    filled
                    title="No Data"
                    sx={{
                      py: 5,
                    }}
                  />
                </Stack>
              )}
            </Scrollbar>
          </TableContainer>
          {/* {!disable && (
            <Box sx={{ p: 2, textAlign: 'right' }}>
              <Button
                size="small"
                color="inherit"
                onClick={HandlePage}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
              >
                View All
              </Button>
            </Box>
          )} */}
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

DashboardCampaign.propTypes = {
  values: PropTypes.array,
  date: PropTypes.array,
};
