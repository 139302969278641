import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import { Box, Paper, CardMedia, Typography, ListItemText } from '@mui/material';

import { USDT } from 'src/hooks/use-USDT';

import rocket from 'src/assets/Dashboard/rocket.png';
import windows from 'src/assets/Dashboard/windows.png';
import building from 'src/assets/Dashboard/building.png';

export default function AdminDashboardWallet({ settingsData }) {
  console.log(settingsData);
  const walletDetails = [
    {
      img: windows,
      label: 'Company wallet',
      subHeader: 'company wallet serves as the central financial repository for the organization',
      value: settingsData?.company_wallets?.company_wallet_amount,
    },
    {
      img: building,
      label: 'Maintenance wallet',
      subHeader: 'wallet is designated for covering maintenance costs associated with systems',
      value: settingsData?.company_wallets?.maintenance_wallet_amount,
    },
    {
      img: rocket,
      label: 'Future projects wallet',
      subHeader: 'Funds allocated for future projects, innovations, and expansions.',
      value: settingsData?.company_wallets?.future_project_wallet_amount,
    },
  ];
  return (
    <Card sx={{ padding: 2, minHeight: 436 }}>
      <Typography sx={{ p: 2, fontSize: '14px', fontWeight: '700', pl: 1.5 }}>Wallets</Typography>
      <Box pt={1}>
        {walletDetails?.map((item) => (
          <Box p={0.5}>
            <Paper sx={{ border: '1px solid #919EAB1F', padding: 2, mb: 1 }}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <CardMedia component="img" src={item?.img} sx={{ height: 30, width: 30, mr: 3 }} />
                <ListItemText
                  primary={item?.label}
                  secondary={item?.subHeader}
                  primaryTypographyProps={{ typography: 'subtitle1', noWrap: true }}
                  secondaryTypographyProps={{
                    mt: 0.5,
                    component: 'span',
                    typography: 'caption',
                    color: '#637381',
                  }}
                />
                <Typography variant="h6">
                  {item?.value}&nbsp;
                  {USDT}
                </Typography>
              </Box>
            </Paper>
          </Box>
        ))}
      </Box>
    </Card>
  );
}

AdminDashboardWallet.propTypes = {
  settingsData: PropTypes.object,
};
