import { createSlice } from '@reduxjs/toolkit';

import {
  getAllCampaign,
  getAllCampaignsList,
  getCampaignsDetails,
  getAllSearchTermFromCampaign,
  getAllAdGroupListFromCampaign,
  getAllNegativeTargetFromCampaign,
} from '../api/campaign';

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: {
    loading: false,
    error: {},
    campaigns: {},
    campaignDetails: {},
    allCampaignLists: {},
    allAdGroupInCampaign: {},
    allSearchTermInCampaign: {},
    allNegTargetInCampaign: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all campaigns dropdowns
    [getAllCampaign.pending]: (state) => {
      state.loading = true;
    },
    [getAllCampaign.fulfilled]: (state, action) => {
      state.loading = false;
      state.campaigns = action.payload;
      state.error = {};
    },
    [getAllCampaign.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // all lists
    [getAllCampaignsList.pending]: (state) => {
      state.loading = true;
    },
    [getAllCampaignsList.fulfilled]: (state, action) => {
      state.loading = false;
      state.allCampaignLists = action.payload;
      state.error = {};
    },
    [getAllCampaignsList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // singleView
    [getCampaignsDetails.pending]: (state) => {
      state.loading = true;
    },
    [getCampaignsDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.campaignDetails = action.payload;
      state.error = {};
    },
    [getCampaignsDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // adGroup list in campaign
    [getAllAdGroupListFromCampaign.pending]: (state) => {
      state.loading = true;
    },
    [getAllAdGroupListFromCampaign.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAdGroupInCampaign = action.payload;
      state.error = {};
    },
    [getAllAdGroupListFromCampaign.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // search term in campaign
    [getAllSearchTermFromCampaign.pending]: (state) => {
      state.loading = true;
    },
    [getAllSearchTermFromCampaign.fulfilled]: (state, action) => {
      state.loading = false;
      state.allSearchTermInCampaign = action.payload;
      state.error = {};
    },
    [getAllSearchTermFromCampaign.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // negative target in campaign
    [getAllNegativeTargetFromCampaign.pending]: (state) => {
      state.loading = true;
    },
    [getAllNegativeTargetFromCampaign.fulfilled]: (state, action) => {
      state.loading = false;
      state.allNegTargetInCampaign = action.payload;
      state.error = {};
    },
    [getAllNegativeTargetFromCampaign.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = campaignSlice.actions;

export default campaignSlice.reducer;
