import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createMemberClub,
  deleteMemberClub,
  getAllMemberClubs,
  getMemberClubDetails,
  updateMemberClubDetails,
  getAllMemberClubsForMember,
  createMemberClubWithdrawalRequest,
} from '../api/memberClub';

export const memberClubSlice = createSlice({
  name: 'memberClub',
  initialState: {
    loading: false,
    error: {},
    // staff
    memberClubs: {},
    memberClubDetails: {},
    memberClubWithdrawalRequests: {},
    memberClubsForMember: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [createMemberClub.pending]: (state) => {
      state.loading = true;
    },
    [createMemberClub.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.memberClubs;
      const newList = jsonState?.memberClubs;
      const modifiedList = {
        ...jsonState,
        memberClubs: Array.isArray(newList) ? [action.payload, ...newList] : [action.payload],
      };
      state.loading = false;
      state.memberClubs = modifiedList;
      state.error = {};
    },
    [createMemberClub.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllMemberClubs.pending]: (state) => {
      state.loading = true;
    },
    [getAllMemberClubs.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberClubs = action.payload;
      state.error = {};
    },
    [getAllMemberClubs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getMemberClubDetails.pending]: (state) => {
      state.loading = true;
    },
    [getMemberClubDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberClubDetails = action.payload;
      state.error = {};
    },
    [getMemberClubDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updateMemberClubDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateMemberClubDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.memberClubs;
      const modifiedList = {
        ...jsonState,
        memberClubs: jsonState.memberClubs?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      state.memberClubs = modifiedList;
      state.error = {};
    },
    [updateMemberClubDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteMemberClub.pending]: (state) => {
      state.loading = true;
    },
    [deleteMemberClub.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.memberClubs;
      const modifiedList = {
        ...jsonState,
        memberClubs: jsonState.memberClubs?.filter((member) => member._id !== action.payload),
      };
      state.loading = false;
      state.memberClubs = modifiedList;
      state.error = {};
    },
    [deleteMemberClub.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [createMemberClubWithdrawalRequest.pending]: (state) => {
      state.loading = true;
    },
    [createMemberClubWithdrawalRequest.fulfilled]: (state, action) => {
      state.memberClubWithdrawalRequests = action.payload;
      console.log('payload', action.payload);
      state.loading = false;

      state.error = {};
    },
    [createMemberClubWithdrawalRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getAllMemberClubsForMember.pending]: (state) => {
      state.loading = true;
    },
    [getAllMemberClubsForMember.fulfilled]: (state, action) => {
      state.loading = false;
      state.memberClubsForMember = action.payload;
      state.error = {};
    },
    [getAllMemberClubsForMember.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = memberClubSlice.actions;

export default memberClubSlice.reducer;
