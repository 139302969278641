import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link, Typography } from '@mui/material';

import { Rupee } from 'src/hooks/use-Rupee';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import TextMaxLine from 'src/components/text-max-line/text-max-line';

// ----------------------------------------------------------------------

export default function CampaignTableRowMember({ row, selected }) {
  const settings = useSettingsContext();

  const { campaignName, sales, spend, acos } = row;

  const handleColorChange = (value) => {
    if (value > 60) {
      return '#e54141';
    }
    if (value > 30) {
      return '#ec942c';
    }
    if (value >= 10) {
      return '#1d9f34';
    }
    if (value <= 10) {
      return '#1d9f34';
    }
    return 'primary';
  };
  return (
    <TableRow hover selected={selected}>
      <TableCell
        sx={{
          position: 'sticky',
          left: 0,
          zIndex: 100,
          minWidth: 200,
          overflowX: 'auto',
          borderRadius: 0.5,
          backgroundColor: settings?.themeMode === 'light' ? '#ECF0F1' : '#333',
          '&::-webkit-scrollbar': {
            height: '1px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '4px',
          },
        }}
      >
        <Link href="#" style={{ textDecoration: 'none' }}>
          <TextMaxLine variant="caption" fontWeight={400} color="#4D8FAC" line={1}>
            <>{campaignName}</>
          </TextMaxLine>
        </Link>
      </TableCell>
      <TableCell sx={{ maxWidth: 50 }}>
        <TextMaxLine line={2}>
          <Typography variant="body2">
            {Rupee} {spend?.toFixed(2) || '---'}
          </Typography>
        </TextMaxLine>
      </TableCell>
      <TableCell>
        <Typography fontWeight="400" fontSize="13px">
          {' '}
          {Rupee} {sales?.toFixed(2)}
        </Typography>
      </TableCell>

      <TableCell>
        {acos?.toFixed(2)} %{' '}
        <Iconify icon="ic:round-lens" color={handleColorChange(acos)} width={12} />
      </TableCell>
    </TableRow>
  );
}

CampaignTableRowMember.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
};
