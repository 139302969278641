import moment from 'moment';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';

import ShareIcon from '@mui/icons-material/Share';
import { Paper, Stack, Button, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import copy from 'src/assets/other/copy2.png';
import { frontEndUrl } from 'src/server/api/http';

// ----------------------------------------------------------------------

export default function ReferredViewMemberNoReferral({ data }) {
  const { enqueueSnackbar } = useSnackbar();

  console.log(data);
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const handleClick = () => {
    const memberId = data?.member_id; // Extract member ID if available
    const link = `${paths.auth.register}?ref_id=${memberId}`; // Construct the link with member ID

    if (memberId) {
      window.open(link, '_blank'); // Open the link in a new tab
    } else {
      // Handle the case where member ID is not available (optional)
      console.warn('Member ID not available for referral link.');
    }
  };
  return (
    <Paper
      variant="outlined"
      sx={{
        height: 230,
        display: 'flex',
        flexDirection: 'column',
        py: 3.5,
        px: 2,
        borderRadius: 3,
        border: '1px dashed #ccee',
      }}
    >
      <Stack flexDirection="" alignItems="start" justifyContent="start">
        <Typography variant="body2" sx={{ color: '#919EAB', pb: 1 }}>
          To obtain Club Memberships and earn Club Incomes, simply refer three members to join our
          system.{' '}
        </Typography>
        <Stack mt={1.2}>
          <Typography variant="body2" color="#919EAB">
            Joined on
          </Typography>{' '}
          <Typography variant="body2" sx={{}}>
            {moment(data?.createdAt).format('DD MMM YYYY h:mm A')}{' '}
          </Typography>
        </Stack>
        <Stack gap={1} flexDirection="row" mt={2}>
          <CopyToClipboard
            // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
            text={`${frontEndUrl}${paths.auth.register}?ref_id=${data?.member_id}`}
            onCopy={handleCopy}
          >
            <Button
              size="small"
              variant="contained"
              startIcon={<CardMedia component="img" src={copy} alt="copy" sx={{ width: '15px' }} />}
              sx={{
                width: '160px',
                backgroundColor: '#00A76F',
                height: '34px',
                '&:hover': { backgroundColor: '#047f56' },
              }}
            >
              Copy Referral Link{' '}
            </Button>
          </CopyToClipboard>
          <Button
            size="small"
            variant="outlined"
            startIcon={<ShareIcon />}
            onClick={handleClick}
            sx={{ color: '#00A76F', width: '110px', height: '34px' }}
          >
            Share Link
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
ReferredViewMemberNoReferral.propTypes = {
  data: PropTypes.object,
};
