import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import clubReducer from './club';
import commonReducer from './common';
import memberReducer from './member';
import walletReducer from './wallet';
import CountryReducer from './country';
// eslint-disable-next-line import/no-named-as-default
import campaignSlice from './campaign';
import settingsReducer from './settings';
// eslint-disable-next-line import/no-named-as-default
import analyticsSlice  from './analytics';
import dashboardReducer from './dashboard';
import memberClubReducer from './memberClub';
import walletAddressReducer from './walletAddress';
// eslint-disable-next-line import/no-named-as-default
import dropDownListReducerSlicer from './dropDownLists';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    country: CountryReducer,
    member: memberReducer,
    memberClub: memberClubReducer,
    club: clubReducer,
    settings: settingsReducer,
    analytics:analyticsSlice ,
    campaigns: campaignSlice,
    wallet: walletReducer,
    dropDownLists: dropDownListReducerSlicer,
    walletAddress: walletAddressReducer,
  },
});
