import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const getAllWalletAddress = createAsyncThunk(
  'member/wallet-address/list',
  async ({ page, search, limit, dispatch }) => {
    try {
      const response = await get(
        `/member/wallet-address?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createWalletAddress = createAsyncThunk(
  'member/wallet-address/new',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/member/wallet-address/new `;
      const response = await post(URL, state);
      if (response) {
        // dispatch(
        //   getAllWalletAddress({
        //     page: 1,
        //     search: '',
        //     dispatch,
        //   })
        // );
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Wallet Address created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getWalletAddressDetails = createAsyncThunk(
  'member/wallet-address/single',
  async ({ walletId, dispatch }) => {
    try {
      console.log('ID:', walletId);
      const response = await get(`/member/wallet-address/${walletId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateWalletAddressDetails = createAsyncThunk(
  'member/wallet-address/update',
  async ({ state, walletId, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/member/wallet-address/${walletId}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }
        // dispatch(
        //   getAllSupportLists({
        //     page: 1,
        //     search: '',
        //     dispatch,
        //     limit: sessionStorage.getItem('rowsPerPage_support_list'),
        //     project: '',
        //     date: '',
        //     sort: 'new',
        //   })
        // );
        const credentials = {
          page: 1,
          search: '',
          limit: 10,
          dispatch,
        };
        dispatch(getAllWalletAddress(credentials));
        dispatch(activeSnack({ type: 'success', message: 'Wallet Address updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteWalletAddress = createAsyncThunk(
  'member/wallet-address/delete',
  async ({ walletId, dispatch }) => {
    try {
      const response = await del(`/member/wallet-address/${walletId}`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Wallet Address deleted Successfully' }));
        const credentials = {
          page: 1,
          search: '',
          limit: 10,
          dispatch,
        };
        dispatch(getAllWalletAddress(credentials));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

// admin wallet Address

export const getAllWalletAddressAdmin = createAsyncThunk(
  'admin/wallet-address/list',
  async ({ page, search, limit, dispatch, id }) => {
    try {
      const response = await get(
        `/member/admin/wallet-address/${id}?page=${page && page}&search=${search && search}&limit=${
          limit || 5
        }`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log('error =>', error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const createWalletAddressAdmin = createAsyncThunk(
  'admin/wallet-address/new',
  async ({ state, dispatch, handleClose, handleClear, id }) => {
    try {
      const URL = `/member/admin/wallet-address/new/${id} `;
      const response = await post(URL, state);
      if (response) {
        // dispatch(
        //   getAllWalletAddress({
        //     page: 1,
        //     search: '',
        //     dispatch,
        //   })
        // );
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'Wallet Address created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getWalletAddressDetailsAdmin = createAsyncThunk(
  'admin/wallet-address/single',
  async ({ walletId, dispatch }) => {
    try {
      console.log('ID:', walletId);
      const response = await get(`/member/admin/wallet-address/${walletId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateWalletAddressDetailsAdmin = createAsyncThunk(
  'admin/wallet-address/update',
  async ({ state, walletId, dispatch, handleClose, handleClear, id }) => {
    try {
      const URL = `/member/admin/wallet-address/${walletId}/${id}`;

      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        if (handleClear) {
          handleClear();
        }

        dispatch(activeSnack({ type: 'success', message: 'Wallet Address updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteWalletAddressAdmin = createAsyncThunk(
  'admin/wallet-address/delete',
  async ({ walletId, id, dispatch }) => {
    try {
      const response = await del(`/member/admin/wallet-address/${walletId}/${id}`);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Wallet Address deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
