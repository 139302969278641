import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import { Box, Button, CardMedia, Typography, ListItemText } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import image from 'src/assets/other/avatar_1.jpg';
import { getAllMembers } from 'src/server/api/member';

import Iconify from 'src/components/iconify';

import ScreenshotView from 'src/sections/Member/ScreenshotView';

export default function AdminDashboardVerifiedList({ chart, ...other }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState({ status: false, data: {} });

  const { membersList } = useSelector((state) => ({
    membersList: state.member.members?.members,
  }));
  const HandlePage = () => {
    navigate(paths.dashboard.member, {});
  };

  useEffect(() => {
    const credentials = {
      page: '',
      search: '',
      dispatch,
      limit: 5,
      sort: 'UNVERIFIED',
      date: '',
    };
    dispatch(getAllMembers(credentials));
  }, [dispatch]);

  return (
    <>
      <ScreenshotView open={isOpen?.status} setOpen={setIsOpen} data={isOpen?.data} />

      <Card sx={{ padding: 2, minHeight: 460 }}>
        <Typography variant="h6" py={2}>
          Transaction History
        </Typography>

        <Box pt={1}>
          {membersList?.map((item) => (
            <Box pb={2} px={1}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <CardMedia
                  component="img"
                  src={image}
                  sx={{ height: 50, width: 50, mr: 1.5, borderRadius: '50%' }}
                />
                <ListItemText
                  primary={item?.name}
                  secondary={item?.member_id}
                  primaryTypographyProps={{ typography: 'subtitle1', noWrap: true }}
                  secondaryTypographyProps={{
                    mt: 0.5,
                    component: 'span',
                    typography: 'caption',
                    color: '#637381',
                  }}
                />
                <Button
                  color="info"
                  variant="outlined"
                  onClick={() => setIsOpen({ status: true, data: item })}
                >
                  Verify
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{ p: 2, textAlign: '' }}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          position="absolute"
          bottom={5}
          right={0}
        >
          <Button
            size="small"
            color="inherit"
            onClick={HandlePage}
            endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={18} sx={{ ml: -0.5 }} />}
          >
            View All
          </Button>
        </Box>
      </Card>
    </>
  );
}

AdminDashboardVerifiedList.propTypes = {
  chart: PropTypes.object,
};
