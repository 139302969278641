import PropTypes from 'prop-types';

import PersonIcon from '@mui/icons-material/Person';
import { Paper, Stack, Typography } from '@mui/material';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MarkEmailUnreadRoundedIcon from '@mui/icons-material/MarkEmailUnreadRounded';
// ----------------------------------------------------------------------

export default function ReferredViewMember({ data }) {
  console.log(data);
  return (
    <Paper
      variant="outlined"
      sx={{
        height: 230,
        display: 'flex',
        flexDirection: 'column',
        py: 3.5,
        px: 2,
        borderRadius: 3,
        backgroundColor: '#1d2a35',
      }}
    >
      <Stack flexDirection="" alignItems="start" justifyContent="start">
        <Typography variant="h4" sx={{ color: 'white', pb: 1 }}>
          Referred by
        </Typography>
        <Stack gap={1} ml={1}>
          <Stack flexDirection="row" alignItems="center">
            <DeviceHubIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
            <Typography variant="body2" sx={{ color: 'white' }}>
              {data?.referral_member?.member_id}
            </Typography>
          </Stack>
          <Stack flexDirection="row" alignItems="center">
            <PersonIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
            <Typography variant="body2" sx={{ color: 'white' }}>
              {data?.referral_member?.name}
            </Typography>
          </Stack>
          <Stack flexDirection="row" alignItems="center">
            <MarkEmailUnreadRoundedIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" />{' '}
            &nbsp;
            <Typography variant="body2" sx={{ color: 'white' }}>
              {' '}
              {data?.referral_member?.email}
            </Typography>
          </Stack>
          <Stack flexDirection="row" alignItems="center">
            <LocalPhoneRoundedIcon sx={{ fontSize: 14, color: 'white' }} fontSize="small" /> &nbsp;
            <Typography variant="body2" sx={{ color: 'white' }}>
              {data?.referral_member?.phone}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}
ReferredViewMember.propTypes = {
  data: PropTypes.object,
};
