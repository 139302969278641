import { createSlice } from '@reduxjs/toolkit';

import {
  createWallet,
  getAllWallet,
  deleteWallet,
  getWalletDetails,
  updateWalletDetails,
  getAllMemberWalletAddress,
} from '../api/Wallet';

const covertToJSON = (data) => JSON.parse(JSON.stringify(data));

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    loading: false,
    error: {},
    // wallet
    walletList: {},
    walletDropList: [],
    walletDetails: {},
    walletAddress: {},
  },
  reducers: {
    clearWalletError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // create wallet details
    [createWallet.pending]: (state) => {
      state.loading = true;
    },
    [createWallet.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletList;
      const modifiedWalletList = {
        ...jsonState,
        list: Array.isArray(jsonState?.list)
          ? // eslint-disable-next-line no-unsafe-optional-chaining
            [...jsonState?.list, action.payload]
          : [action.payload],
      };
      state.loading = false;
      state.walletDetails = action.payload;
      state.walletList = modifiedWalletList;
      state.error = {};
    },
    [createWallet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all wallet
    [getAllWallet.pending]: (state) => {
      state.loading = true;
    },
    [getAllWallet.fulfilled]: (state, action) => {
      state.loading = false;
      state.walletList = action.payload;
      state.error = {};
    },
    [getAllWallet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single wallet details
    [getWalletDetails.pending]: (state) => {
      state.loading = true;
    },
    [getWalletDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.walletDetails = action.payload;
      state.error = {};
    },
    [getWalletDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update wallet details
    [updateWalletDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateWalletDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.walletDetails = action.payload;
      state.error = {};
    },
    [updateWalletDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteWallet.pending]: (state) => {
      state.loading = true;
    },
    [deleteWallet.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletList;
      const modifiedWalletList = {
        ...jsonState,
        list: jsonState.list?.filter((wallet) => wallet._id !== action.payload),
      };
      state.loading = false;
      state.walletList = modifiedWalletList;
      state.error = {};
    },
    [deleteWallet.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // member wallet  address
    [getAllMemberWalletAddress.pending]: (state) => {
      state.loading = true;
    },
    [getAllMemberWalletAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.walletAddress = action.payload;
      state.error = {};
    },
    [getAllMemberWalletAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearWalletError } = walletSlice.actions;

export default walletSlice.reducer;
