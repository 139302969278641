// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetRole } from 'src/hooks/useHandleSessions';

import { get, post } from './http';

export const getSidebarItemCountData = createAsyncThunk(
  'static/sidebar/count',
  async ({ enqueueSnackbar, userType }) => {
    try {
      const response = await get(`/static/dashboard/counts?user_type=${userType && userType}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getAmazonConnectLink = createAsyncThunk(
  'amazon/connection/link',
  async ({ enqueueSnackbar, region, navigate }) => {
    console.log(region);
    try {
      const response = await post(`/advertising-account/user/connect`, { region });

      console.log("response =>",response);
      if (response) {
        enqueueSnackbar('Updated Successfully');
        const redirectUrl = response?.redirectUrl;
        if (redirectUrl) {
          if (redirectUrl.startsWith('http')) {
            window.location.href = redirectUrl; 
          } else {
            useNavigate()(redirectUrl);
          }
        }
      }
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);



// export const getDashboardData = createAsyncThunk('static/dashboard/count', async ({ dispatch }) => {
//   try {

//     const response = await get('/static/advertiser/dashboard/totals');
//     if (response) {
//       return response;
//     }
//     return response;
//   } catch (error) {
//     dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
//     throw error;
//     // const errorMessage = error?.response?.data?.message || error.message;
//     // enqueueSnackbar(errorMessage, { variant: 'error' });
//     // return Promise.reject(errorMessage);
//   }
// });
// dashboard charts
export const getDashboardChartData = createAsyncThunk(
  'campaign-chart/member',
  async ({ enqueueSnackbar, groupBy, campaign, dates,selectedSort,selectedSort2 }) => {
    console.log("dates =>",dates);
    try {
      const response = await get(
        `/analytics/advertiser/graph?groupBy=${groupBy || ''}&campaignId=${
          campaign || ''
        }&from=${dates ? dates[0]?.startDate || '' : ''}&to=${dates ? dates[0]?.endDate || '' : ''}&value1=${selectedSort|| ''}&value2=${selectedSort2 ||''}`
      );
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getIncomeChartData = createAsyncThunk(
  'income-chart/member',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`/wallet-transaction/member/income/graph`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const getPartnerBalanceChartData = createAsyncThunk(
  'earning/admin/increment',
  async ({ enqueueSnackbar, year }) => {
    try {
      const response = await get(`/earning/admin/increment/graph/monthly?&year=${year || ''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// school course progression graph
export const getProgressionGraphData = createAsyncThunk(
  'school-course/school-user/graph/data',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get('/school-course/school-user/graph/data');
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getAmazonDataFetching = createAsyncThunk(
  'amazon/data/fetching',
  async ({ enqueueSnackbar, code, navigate }) => {
    try {
      const response = await post(`/advertising-account/user/new/${code}`);
      console.log(response);
      if (response) {
        navigate(paths?.dashboard?.root);
        useSetRole(response?.user?.role, response?.user?.role);
      }
      return response;
    } catch (error) {
      console.log(error);
      if (error?.response?.data) {
        navigate(paths?.dashboard?.root);
      }
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
