// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  website: '/website',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/landing',
    academy_landing: ROOTS.LMS,
    course_view: (id) => `${ROOTS.LMS}/course/${id}`,
    plan: `/plan`,
    about: `/home`,
    contact: `/contact`,
    terms_conditions: `/terms`,
    privacy_policy: `/privacy`,
  },
  // AUTH
  auth: {
    login: `/login/admin`,
    login_member: `/login`,
    login_otp_member: `/login/otp`,

    register: `/register`,
    forgot_password: `/forgot/password`,

    logout: `${ROOTS.AUTH}/logout`,

    create_password: (type, token) => `/create/password/:${type}/:${token}`,
    forgot_password_email: (type, token) => `/password/reset/:${token}`,
    password_verification: (type, token) => `/invitation/:${type}/:${token}`,
    onBoarding: `/onBoarding`,
    OnBoarding2: `/onBoarding/2`,
    OnBoardingAmazonConnect: `/onBoarding/connect`,
    redirect: `/redirect`,
  },
  website: {},
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    profile: `${ROOTS.DASHBOARD}/profile`,
    analytics: `${ROOTS.DASHBOARD}/analytics`,
    adManager: `${ROOTS.DASHBOARD}/ad-manager`,
    adManagerCreate: `${ROOTS.DASHBOARD}/ad-manager/create`,
    campaignDetails: (id) => `${ROOTS.DASHBOARD}/ad-manager/detail/${id}`,
    transaction: `${ROOTS.DASHBOARD}/transactions`,
    income: `${ROOTS.DASHBOARD}/income`,
    club: `${ROOTS.DASHBOARD}/clubs`,
    withdrawal_requests: `${ROOTS.DASHBOARD}/withdrawal/requests`,

    member: `${ROOTS.DASHBOARD}/member`,
    member_view: (id) => `${ROOTS.DASHBOARD}/member/${id}`,
    member_create: `${ROOTS.DASHBOARD}/member/create`,

    settings: `${ROOTS.DASHBOARD}/settings`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  member: 'Member',
};
