import { memo } from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';

// import { useSettingsContext } from 'src/components/settings';

import NavList from './nav-list';

// ----------------------------------------------------------------------

function NavSectionMini({ data, slotProps, ...other }) {
  // const settings = useSettingsContext();

  return (
    <Stack
      component="nav"
      id="nav-section-mini"
      spacing={`${slotProps?.gap || 4}px`}
      {...other}
      // sx={{
      //   ':hover': settings.onUpdate(
      //     'themeLayout',
      //     settings.themeLayout === 'vertical' ? 'mini' : 'vertical'
      //   ),
      // }}
    >
      {data.map((group, index) => (
        <Group key={group.subheader || index} items={group.items} slotProps={slotProps} />
      ))}
    </Stack>
  );
}

NavSectionMini.propTypes = {
  data: PropTypes.array,
  slotProps: PropTypes.object,
};

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

function Group({ items, slotProps }) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title} data={list} depth={1} slotProps={slotProps} />
      ))}
    </>
  );
}

Group.propTypes = {
  items: PropTypes.array,
  slotProps: PropTypes.object,
};
