import { m } from 'framer-motion';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { getMemberProfile } from 'src/server/api/auth';

import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';
import TextTruncate from 'src/components/Typography/TextTruncate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover({ changeTheme }) {
  const router = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mdUp = useResponsive('up', 'md');
  const { role } = useGetRoles();
  const settings = useSettingsContext();
  // const { data: userType } = useGetEncryptLocalData('partnerType');
  const { account } = useSelector((state) => ({
    account: state.auth?.user,
    // partnerProfile: state.partner.profile,

    user: state.auth.user,
  }));

  useEffect(() => {
    const credentialsYear = {
      enqueueSnackbar,
    };
    dispatch(getMemberProfile(credentialsYear));
  }, [dispatch, enqueueSnackbar, role]);

  const popover = usePopover();
  const OPTIONS = [
    {
      label: 'Home',
      linkTo: role === 'student' ? paths.public.academy_landing : paths.dashboard.root,
    },
    // {
    //   label: 'Profile',
    //   linkTo:
    //     role === 'student' ? paths.auth.student_profile : `${paths.dashboard.profile}#general`,
    // },
    // ...(user && user.user_roles && user.user_roles.length > 1
    //   ? [
    //       {
    //         label: 'Change Role',
    //         linkTo: `${paths.dashboard.profile}#security`,
    //       },
    //     ]
    //   : []),
    // {
    //   label: 'Change Role',
    //   linkTo: `${paths.dashboard.profile}#security`,
    // },
    // {
    //   label: 'Settings',
    //   linkTo: `${paths.dashboard.profile}#security`,
    // },
  ];

  // const signOff = async () => {
  //   popover.onClose();
  //   navigate(paths.auth.logout);
  //   if (changeTheme) {
  //     changeTheme('light');
  //   }
  //   settings.onUpdate('themeLayout', settings.themeLayout === 'mini' ? 'vertical' : 'vertical');
  // };

  const logout = () => {
    popover.onClose();
    navigate(paths.public.landing);
    sessionStorage.clear();
    if (changeTheme) {
      changeTheme('light');
    }

    settings.onUpdate('themeLayout', settings.themeLayout === 'mini' ? 'vertical' : 'vertical');
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };
  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            backgroundColor: '#106E58',
            // (theme) =>
            //   `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={
            account?.profile_image && account.profile_image?.public_id
              ? account.profile_image.url
              : 'default-image-url'
          }
          alt={account?.name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
            // border: 'solid 2px 106E58',
          }}
        >
          {account?.name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      {mdUp && (
        <Stack onClick={popover.onOpen} alignItems="center" px={2}>
          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
            {account?.firstName}
            {/* Ameen Arshad Nediya */}
          </Typography>
          <Chip
            sx={{ ml: 1, textTransform: 'capitalize' }}
            label={role}
            variant="filled"
            size="small"
          />
        </Stack>
      )}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: role === 'school_user' ? 230 : 200, p: 0 }}
      >
        <Stack sx={{ position: 'relative' }}>
          <Box sx={{ p: 2, pb: 1.5 }}>
            <Typography
              variant="subtitle2"
              noWrap
              sx={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', mb: 1 }}
            >
              <TextTruncate text={account?.name} width={50} />
              <Chip sx={{ ml: 0 }} label={role} variant="outlined" color="warning" size="small" />
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {account?.email}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {account?.phone}
            </Typography>
          </Box>
          {account?.is_guest && (
            <Chip
              sx={{ position: 'absolute', top: 10, right: 10 }}
              size="small"
              color="warning"
              label="Guest"
            />
          )}
          {/* {account?.is_verified && (
            <Chip
              sx={{ position: 'absolute', top: 10, right: 10, fontSize: '12px' }}
              size="small"
              color="success"
              label="Verified"
              icon={<VerifiedIcon fontSize="small" />}
            />
          )} */}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack>
          <MenuItem
            sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
            onClick={logout}
          >
            Logout
          </MenuItem>
          {/* <Tooltip title="Logout from all device">
            <MenuItem id="signOffId" onClick={signOff} sx={{ m: 1 }}>
              Sign Off
            </MenuItem>
          </Tooltip> */}
        </Stack>
      </CustomPopover>
    </>
  );
}

AccountPopover.propTypes = {
  changeTheme: PropTypes.any,
};
