import { useMemo } from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';

import { paths } from 'src/routes/routes/paths';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

// ----------------------------------------------------------------------

export function useNavData() {
  const { role } = useGetRoles();

  // const {  } = useSelector((state) => ({
  //   sidebarCounts: state.dashboard.sidebarCounts,
  //   MemberProfileDetails: state.member.memberProfileDetails.member,
  //   roleCheck: state.auth.user,
  // }));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const adminSideBarData = useMemo(() => [
    {
      subheader: 'Dashboard',
      items: [
        { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: <DashboardIcon /> },
        {
          title: 'Members',
          path: paths.dashboard.member,
          icon: icon('ic_user'),

          // info: <Label color="warning">{counts?.totalSalesCount || 0}</Label>,
        },
        { title: 'Clubs', path: paths.dashboard.club, icon: icon('ic_analytics') },

        {
          title: 'Withdrawal',
          path: paths.dashboard.withdrawal_requests,
          icon: icon('ic_banking'),
        },
        {
          title: 'Transaction History',
          path: paths.dashboard.transaction,
          icon: icon('ic_blog'),
        },
        {
          title: 'Settings',
          path: paths.dashboard.settings,
          icon: <Iconify icon="solar:settings-bold-duotone" width={24} />,
          // info: <Label color="warning">{counts?.totalSalesCount || 0}</Label>,
        },
      ],
    },
  ]);

  const memberSideBarData = useMemo(
    () => [
      {
        subheader: 'Overview',
        items: [
          { title: 'Dashboard', path: `${paths.dashboard.root}/app`, icon: icon('ic_dashboard') },
          {
            title: 'Analytics',
            icon: icon('ic_analytics'),
            path: paths.dashboard?.analytics,
            disabled: role === 'user' && 'true',
          },
          {
            title: 'Ad Manager',
            icon: icon('ic_banking'),
            path: paths.dashboard?.adManager,
            disabled: role === 'user' && 'true',
          },
          {
            title: 'Automation',
            icon: icon('ic_invoice'),
            path: paths.dashboard?.root,
            disabled: true,
          },
          // {
          //   title: 'Income',
          //   path: MemberProfileDetails?.is_verified ? paths.dashboard.income : '#',
          //   disabled:  roleCheck?.role === "advertiser" ? 'false' : 'true',
          //   icon: icon('ic_analytics'),
          // },
          // {
          //   title: 'Withdrawal',
          //   icon: icon('ic_banking'),
          //   path: MemberProfileDetails?.is_verified ? paths.dashboard.withdrawal_requests : '#',
          //   disabled: roleCheck?.role === "advertiser" ? 'false' : 'true',
          // },
          // {
          //   title: 'Transaction History',
          //   icon: icon('ic_blog'),
          //   path: MemberProfileDetails?.is_verified ? paths.dashboard.transaction : '#',
          //   disabled:  roleCheck?.role === "advertiser" ? 'false' : 'true',
          // },
        ],
      },
    ],
    [role]
  );

  const data = useMemo(() => {
    const handleSideBarDataWithRole = () => {
      switch (role) {
        case 'admin':
          return adminSideBarData;
        case 'user':
          return memberSideBarData;
        case 'advertiser':
          return memberSideBarData;
        default:
          return [];
      }
    };

    return handleSideBarDataWithRole();
  }, [adminSideBarData, memberSideBarData, role]);

  return data;
}

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };
