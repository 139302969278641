import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getAllPortfolio = createAsyncThunk(
  'portfolio/list',
  async ({ search, dispatch }) => {
    try {
      const response = await get(
        `/portfolio/advertiser/all?search=${search ||''}`)
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllAdGroup = createAsyncThunk(
  'ad-group/list',
  async ({ page, search, dispatch }) => {
    try {
      const response = await get(
        `/ad-group/advertiser/all?page=${page || page}&search=${search ||''}`)
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllTarget = createAsyncThunk(
  'target/list',
  async ({ page, search, dispatch }) => {
    try {
      const response = await get(
        `/targeting-clause/advertiser/all?page=${page || page}&search=${search ||''}`)
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSearchTerm = createAsyncThunk(
  'search-term/list',
  async ({ page, search, dispatch }) => {
    try {
      const response = await get(
        `/search-term/advertiser/all?page=${page || page}&search=${search ||''}`)
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllProducts = createAsyncThunk(
  'products/list',
  async ({ page, search, dispatch }) => {
    try {
      const response = await get(
        `/product-ad/advertiser/all?page=${page || page}&search=${search ||''}`)
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
