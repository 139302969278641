import { createSlice } from '@reduxjs/toolkit';

import {
  getApp,
  getDashboardData,
  getDashboardDataHighestAcos,
  getDashboardDataPerformance,
  getDashboardDataHighestSpend,
} from '../api/app';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    loading: false,
    error: {},
    app: {},
    dashboardData: {},
    dashboardDataPpc: {},
    dashboardDataAcos: {},
    dashboardDataSpend: {},
  },
  reducers: {},
  extraReducers: {
    [getApp.pending]: (state) => {
      state.loading = true;
    },
    [getApp.fulfilled]: (state, action) => {
      state.loading = false;
      state.app = action.payload;
    },
    [getApp.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getDashboardData.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardData.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardData = action.payload;
      state.error = {};
    },
    [getDashboardData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // ppc perfomance

    [getDashboardDataPerformance.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardDataPerformance.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardDataPpc = action.payload;
      state.error = {};
    },
    [getDashboardDataPerformance.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // acos
    [getDashboardDataHighestAcos.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardDataHighestAcos.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardDataAcos = action.payload;
      state.error = {};
    },
    [getDashboardDataHighestAcos.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // spend
    [getDashboardDataHighestSpend.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardDataHighestSpend.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardDataSpend = action.payload;
      state.error = {};
    },
    [getDashboardDataHighestSpend.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default appSlice.reducer;
