import { useState } from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import { Box, CardMedia, Typography } from '@mui/material';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';

import { USDT } from 'src/hooks/use-USDT';

import club1 from 'src/assets/other/club1.png';
import club2 from 'src/assets/other/club2.png';
import club3 from 'src/assets/other/club3.png';
import club4 from 'src/assets/other/club4.png';
import club5 from 'src/assets/other/club5.png';
import imgClubIncome from 'src/assets/other/extra.png';

import EditClubDialog from './EditClubDialog';

// ----------------------------------------------------------------------

export default function AllClub({ clubList, dashboard }) {
  const imageArray = [club1, club2, club3, club4, club5];

  const combinedArray = imageArray.map((image, index) => ({
    image,
    club: clubList && clubList[index] ? clubList[index] : null,
  }));

  console.log(imageArray);
  const [isOpenDialog, setIsOpenDialog] = useState({ status: false, updateValues: {} });

  return (
    <>
      <EditClubDialog
        open={isOpenDialog?.status}
        setOpen={setIsOpenDialog}
        isUpdate={isOpenDialog?.updateValues}
      />
      <Card sx={{ pt: 2 }}>
        <Box display="flex" alignItems="center">
          <Box p={0}>
            <CardMedia src={imgClubIncome} component="img" sx={{ height: 100, width: 100 }} />
          </Box>
          <Box pl={4}>
            <Typography variant="subtitle2">Club Income</Typography>
            <Typography variant="h6" py={0.5}>
              {!dashboard && `500 ${USDT}`}
            </Typography>
            <Typography sx={{ color: '#637381', fontSize: 12 }}>
              See how much is in your wallet for every club.
            </Typography>
          </Box>
        </Box>
        <Stack spacing={2} sx={{ p: 2 }}>
          {(combinedArray || []).map((contact) => (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <>
                <Box display="flex" alignItems="center" justifyContent="center" key={contact.id}>
                  <Box>
                    <CardMedia
                      src={contact?.image}
                      component="img"
                      sx={{ width: 48, height: 48, mr: 2 }}
                    />
                  </Box>

                  <Box>
                    <Typography variant="subtitle2">
                      {contact?.club?.title}{' '}
                      {!dashboard && (
                        <Tooltip title="Edit club details">
                          <CreateRoundedIcon
                            fontSize="14px"
                            sx={{ fontSize: 18, cursor: 'pointer', color: '#919EABCC' }}
                            onClick={() => setIsOpenDialog({ status: true, updateValues: contact })}
                          />
                        </Tooltip>
                      )}
                    </Typography>
                    <ListItemText
                      primary={`${dashboard === false ? `56 members` : ''}`}
                      secondary={` limit up to ${USDT}
  ${contact?.club?.limit_amount}`}
                    />
                  </Box>
                  {/* <Box>
              {' '}
              <Typography>
                limit up to {USDT} {contact?.limit_amount}
              </Typography>
            </Box> */}
                </Box>
                <Box>
                  <Box>
                    <Tooltip title="Balance">
                      <Box variant="subtitle2">
                        {`${clubList?.wallet_balance || 0}  `} {USDT}
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </>
            </Box>
          ))}
        </Stack>
      </Card>
    </>
  );
}

AllClub.propTypes = {
  clubList: PropTypes.array,
  dashboard: PropTypes.bool,
};
