import * as Yup from 'yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import ShareIcon from '@mui/icons-material/Share';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, CardMedia, IconButton, Typography, InputAdornment } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

// import { updateUserProfile } from 'src/server/api/auth';
import copy from 'src/assets/other/copy2.png';
import { frontEndUrl } from 'src/server/api/http';
import { updateMemberPassword, updateMemberProfileDetails } from 'src/server/api/member';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';
// import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

// ----------------------------------------------------------------------

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { MemberProfileDetails } = useSelector((state) => ({
    loading: state.member.loading,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  console.log(MemberProfileDetails?.referral_member);
  const isMobile = useResponsive('down', 'md');

  const password = useBoolean();

  const [imagePreview, setImagePreview] = useState([]);
  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    date_of_birth: Yup.string(),
  });
  const UpdatePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    oldPassword: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });
  console.log(MemberProfileDetails);
  const formattedDob = MemberProfileDetails?.date_of_birth
    ? moment(MemberProfileDetails?.date_of_birth).format('YYYY-MM-DD')
    : '';
  console.log(formattedDob);
  const defaultValues = useMemo(
    () => ({
      name: MemberProfileDetails?.name || '',

      date_of_birth: formattedDob,
    }),
    [MemberProfileDetails?.name, formattedDob]
  );

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema || UpdatePasswordSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const credentialsPasswordChange = {
      state: {
        newPassword: data?.newPassword,
        oldPassword: data?.oldPassword,
      },

      enqueueSnackbar,
    };
    console.log(data);

    const memberDataCredential = {
      state: {
        // ...data,
        name: data?.name,
        date_of_birth: data?.date_of_birth,
        profile_photo: imagePreview[0],
      },
      dispatch,
      enqueueSnackbar,
    };
    if (data?.oldPassword && data?.newPassword) {
      dispatch(updateMemberPassword(credentialsPasswordChange));
    }
    if (!data?.oldPassword || !data?.newPassword) {
      dispatch(updateMemberProfileDetails(memberDataCredential));
    }

    // dispatch(updateMemberPassword(credentialsPasswordChange));
    // if (MemberProfileDetails?._id) {
    //   dispatch(updateMemberProfileDetails(memberDataCredential));
    // }
  });
  console.log(imagePreview);
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        url: URL.createObjectURL(file),
      });

      if (file) {
        setValue('profile_photo', newFile, { shouldValidate: true });
        setImagePreview(newFile);
        // handleSubmit(onSubmit)();
      }
    },
    [setValue]
  );

  useEffect(() => {
    reset({
      name: MemberProfileDetails?.name,
      date_of_birth: formattedDob,
    });
  }, [reset, MemberProfileDetails, formattedDob]);

  // useEffect(() => {
  //   if (MemberProfileDetails?._id) {
  //     setImagePreview(MemberProfileDetails?.profile_photo);
  //   }
  // }, [MemberProfileDetails?._id, MemberProfileDetails?.profile_photo]);
  // useEffect(() => {
  //   if (MemberProfileDetails?._id) {
  //     setImagePreview(
  //       [
  //         {
  //           public_id: MemberProfileDetails?.profile_photo?.public_id,
  //           url: MemberProfileDetails?.profile_photo?.url,
  //         },
  //       ] || []
  //     );
  //   }
  // }, [
  //   MemberProfileDetails?._id,
  //   MemberProfileDetails?.profile_photo?.public_id,
  //   MemberProfileDetails?.profile_photo?.url,
  // ]);
  useEffect(() => {
    if (MemberProfileDetails?.profile_photo) {
      setImagePreview([
        {
          public_id: MemberProfileDetails.profile_photo.public_id,
          url: MemberProfileDetails.profile_photo.url,
        },
      ]);
    } else {
      // Handle the case where profile_photo is undefined
      setImagePreview([]);
    }
  }, [MemberProfileDetails?._id, MemberProfileDetails?.profile_photo]);
  console.log(imagePreview);
  const handleClick = () => {
    const memberId = MemberProfileDetails?.member_id; // Extract member ID if available
    const link = `${paths.auth.register}?ref_id=${memberId}`; // Construct the link with member ID

    if (memberId) {
      window.open(link, '_blank'); // Open the link in a new tab
    } else {
      // Handle the case where member ID is not available (optional)
      console.warn('Member ID not available for referral link.');
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
        {/* <Grid xs={12} md={4}>
          <Card sx={{ pt: 3, pb: 2, px: 3, textAlign: 'center' }}>
            <FileUploadCustom
              uploadUrl="profile"
              setImagesPreview={setImagePreview}
              imagesPreview={imagePreview}
              name="Upload Image"
              isMultiple
              imageSize={4}
              theme={{
                type: 'standard',
                name: 'profile_photo',
                handleDrop,
              }}
              sx={{ marginBottom: '1rem' }}
            />
          </Card>
        </Grid> */}

        <Stack flex={8}>
          <Card sx={{ p: 3, display: 'flex', spacing: 2, flexDirection: 'column' }}>
            <Stack flexDirection="column">
              <Typography variant="h6" mb={2}>
                Update Info
              </Typography>
              <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
                <Card
                  sx={{
                    flex: 1.7,
                    p: 3,
                    border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
                    opacity: 0.72,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <FileUploadCustom
                    uploadUrl="profile"
                    setImagesPreview={setImagePreview}
                    imagesPreview={imagePreview}
                    title="Upload Image"
                    isMultiple
                    imageSize={4}
                    theme={{
                      type: 'standard',
                      name: 'profile_photo',
                      handleDrop,
                    }}
                    sx={{ marginBottom: '1rem' }}
                  />
                </Card>

                <Stack flex={2.3}>
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    }}
                  >
                    <RHFTextField
                      name="name"
                      label="Name"
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                    <RHFTextField
                      name="date_of_birth"
                      label="Date of Birth"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={defaultValues.date_of_birth}
                    />
                  </Box>
                  <Box my={3}>
                    <Typography fontSize={12} color="#637381">
                      Update Password
                    </Typography>
                  </Box>
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    }}
                  >
                    <RHFTextField
                      name="oldPassword"
                      label="Old Password"
                      placeholder="Password"
                      type={password.value ? 'text' : 'password'}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                              <Iconify
                                icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <RHFTextField
                      name="newPassword"
                      label="New Password"
                      placeholder="Confirm Password"
                      type={password.value ? 'text' : 'password'}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={password.onToggle} edge="end">
                              <Iconify
                                icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />{' '}
                  </Box>
                </Stack>
              </Stack>
            </Stack>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{
                  color: 'white',
                  width: 'fit-content',
                  marginLeft: 'auto',
                  backgroundColor: '#22C55E',
                  '&:hover': {
                    backgroundColor: '#0b4e3e',
                  },
                }}
              >
                Update Changes{' '}
              </LoadingButton>
            </Stack>
          </Card>
        </Stack>
        <Stack flex={4}>
          <Card
            sx={{
              p: 3,
              mb: 3,
              border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
              opacity: 0.72,
            }}
          >
            <Stack mb={2}>
              <Typography variant="body2" color="#919EAB">
                Joined on
              </Typography>
              <Typography variant="body2">
                {moment(MemberProfileDetails?.createdAt).format('DD MMM YYYY h:mm A')}
              </Typography>
            </Stack>
            <Stack mb={2}>
              <Typography variant="body2" color="#919EAB">
                Wallet address last changed{' '}
              </Typography>
              <Typography variant="body2">
                {' '}
                {moment(MemberProfileDetails?.updatedAt).format('DD MMM YYYY h:mm A')}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="body2" color="#919EAB">
                You are only able to withdraw funds from your AIZOFT wallet 24 hours after changing
                your wallet address.
              </Typography>
            </Stack>
          </Card>
          {MemberProfileDetails?.referral_member && (
            <Card
              sx={{
                p: 3,
                pb: 2,
                border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
              }}
            >
              <Stack>
                <Typography variant="body2" color="#919EAB">
                  To obtain Club Memberships and earn Club Incomes, simply refer three members to
                  join our system.
                </Typography>
              </Stack>
              <Stack flexDirection="row" mt={9} justifyContent="space-between">
                <Stack gap={1.5} flexDirection="row">
                  <CopyToClipboard
                    // text={`${paths.auth.register}?ref='${MemberProfileDetails?.member_id}`}
                    text={`${frontEndUrl}${paths.auth.register}?ref_id=${MemberProfileDetails?.member_id}`}
                    onCopy={handleCopy}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      startIcon={
                        <CardMedia component="img" src={copy} alt="copy" sx={{ width: '15px' }} />
                      }
                      sx={{
                        width: '160px',
                        backgroundColor: '#00A76F',
                        height: '34px',
                        '&:hover': { backgroundColor: '#047f56' },
                      }}
                    >
                      Copy Referral Link{' '}
                    </Button>
                  </CopyToClipboard>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<ShareIcon />}
                    onClick={handleClick}
                    sx={{ color: '#00A76F', width: '110px', height: '34px' }}
                  >
                    Share Link
                  </Button>
                </Stack>
              </Stack>
            </Card>
          )}
        </Stack>
      </Stack>
    </FormProvider>
  );
}
