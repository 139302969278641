import { useSelector } from 'react-redux';

import { Card } from '@mui/material';
import Stack from '@mui/material/Stack';

import { useResponsive } from 'src/hooks/use-responsive';

import coverImg from 'src/assets/other/set-role.jpg';
import memberProfileImg from 'src/assets/other/user_png.png';

import MemberCover from 'src/sections/Member/MemberSingleView/member-cover';
import WalletAddressMember from 'src/sections/Member/wallet-address-member';
import MemberCards from 'src/sections/Member/MemberSingleView/Small.Widgets';
import PersonalInfo from 'src/sections/Member/MemberSingleView/PersonalInfo';
import ReferredViewMember from 'src/sections/Member/MemberSingleView/ReferredViewMember';
import ReferredViewMemberNoReferral from 'src/sections/Member/MemberSingleView/ReferredViewMemberNoReferral';

import AccountGeneral from './account-general';
// ----------------------------------------------------------------------

// const TABS = [
//   {
//     value: 'general',
//     label: 'General',
//     icon: <Iconify icon="solar:user-id-bold" width={24} />,
//   },

//   {
//     value: 'security',
//     label: 'Security',
//     icon: <Iconify icon="ic:round-vpn-key" width={24} />,
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountView() {
  // const location = useLocation();
  // const settings = useSettingsContext();
  const isMobile = useResponsive('down', 'md');

  const { MemberProfileDetails } = useSelector((state) => ({
    loading: state.member.loading,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  console.log(MemberProfileDetails);

  return (
    <Stack>
      <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3}>
        <Card
          sx={{ flex: 8.1 }}
          // sx={{
          //   mb: 3,
          //   height: 230,
          // }}
        >
          <MemberCover
            username={MemberProfileDetails?.user?.username}
            phone={MemberProfileDetails?.phone}
            email={MemberProfileDetails?.user?.email}
            avatarUrl={MemberProfileDetails?.profile_photo?.url || memberProfileImg}
            coverUrl={MemberProfileDetails?.coverUrl || coverImg}
            verified={MemberProfileDetails?.is_verified}
            paid={MemberProfileDetails?.is_paid}
          />
        </Card>
        <Stack flex={3.9}>
          {MemberProfileDetails?.referral_member && (
            <ReferredViewMember data={MemberProfileDetails} />
          )}
          {!MemberProfileDetails?.referral_member && (
            <ReferredViewMemberNoReferral data={MemberProfileDetails} />
          )}
        </Stack>
      </Stack>
      <Stack flexDirection={isMobile ? 'column' : 'row'} spacing={3} my={3}>
        <Stack flex={4}>
          <MemberCards title={MemberProfileDetails?.member_id} name="memberId" />
        </Stack>
        <Stack flex={4}>
          <MemberCards
            title={MemberProfileDetails?.is_club_member}
            MemberProfileDetails={MemberProfileDetails}
            name="club"
          />
        </Stack>
        <Stack flex={4}>
          <MemberCards title={MemberProfileDetails?.wallet_balance} name="wallet" />
        </Stack>
      </Stack>
      <Stack flexDirection={isMobile ? 'column' : 'row'} gap={3} mb={3}>
        <Stack flex={3.9}>
          <PersonalInfo memberDetails={MemberProfileDetails} />
        </Stack>
        <Stack flex={8.1}>
          <WalletAddressMember data={MemberProfileDetails} />
        </Stack>
      </Stack>
      <AccountGeneral />
    </Stack>
  );
}
