// import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
// @mui
import {
  Stack,
  Paper,
  Dialog,
  CardMedia,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';

// components
import { updateMemberStatus } from 'src/server/api/member';

ScreenshotView.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.object,
};

export default function ScreenshotView({ open, setOpen, data }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };

  const { loading, error } = useSelector((state) => ({
    loading: state.member.loading,
  }));
  console.log(error);

  const [isPaid] = useState('');

  console.log(isPaid);

  const handleChangeVerify = () => {
    const updateCredential = {
      dispatch,
      memberId: data?._id,
    };
    dispatch(updateMemberStatus(updateCredential));
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Payment Screenshot of {data?.name}</DialogTitle>

        {loading ? (
          <Paper
            sx={{
              textAlign: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </Paper>
        ) : (
          <DialogContent sx={{ minWidth: '500px', maxWidth: 'auto' }}>
            <Stack>
              <CardMedia
                component="img"
                sx={{
                  width: 'auto',
                  height: 'auto',
                  lineHeight: 0,
                  borderRadius: 1,
                  bgcolor: 'background.neutral',
                  objectFit: 'contain',
                }}
                src={data?.payment_screenshot?.url}
              />
            </Stack>
            <Stack flexDirection="row" alignItems="center" justifyContent="end" py={3}>
              <Button variant="outlined" color="success" size="small" sx={{ px: 2 }}>
                <Typography fontSize={14} fontWeight={700}>
                  Download
                </Typography>
                &nbsp; &nbsp;
                <CloudDownloadIcon fontSize="small" />
              </Button>
              <Stack px={2}>
                <Button
                  sx={{ px: 3 }}
                  variant="contained"
                  color="success"
                  size="small"
                  width="50%"
                  onClick={() => handleChangeVerify()}
                >
                  <Typography fontSize={14} fontWeight={700}>
                    Verify
                  </Typography>{' '}
                  &nbsp; &nbsp;
                  <VerifiedRoundedIcon fontSize="small" />
                </Button>
              </Stack>
              <Button variant="outlined" size="small" onClick={() => setOpen(false)}>
                <Typography fontSize={14} fontWeight={700}>
                  Close
                </Typography>
              </Button>
            </Stack>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
}
