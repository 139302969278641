import { createSlice } from '@reduxjs/toolkit';

import { getAllTargetAnalytics, getAllAdGroupAnalytics, getAllCampaignAnalytics, getAllProductsAnalytics, getAllPortfolioAnalytics, getAllSearchTermAnalytics, getAllNegativeTargetAnalytics } from '../api/analytics';


export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    loading: false,
    error: {},
    allAnalytics: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all portfolio analytics
    [getAllPortfolioAnalytics.pending]: (state) => {
      state.loading = true;
    },
    [getAllPortfolioAnalytics.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAnalytics = action.payload;
      state.error = {};
    },
    [getAllPortfolioAnalytics.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // all campaign analytics
    [getAllCampaignAnalytics.pending]: (state) => {
      state.loading = true;
    },
    [getAllCampaignAnalytics.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAnalytics = action.payload;
      state.error = {};
    },
    [getAllCampaignAnalytics.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // all ad-group analytics
    [getAllAdGroupAnalytics.pending]: (state) => {
      state.loading = true;
    },
    [getAllAdGroupAnalytics.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAnalytics = action.payload;
      state.error = {};
    },
    [getAllAdGroupAnalytics.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // all target analytics

    [getAllTargetAnalytics.pending]: (state) => {
      state.loading = true;
    },
    [getAllTargetAnalytics.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAnalytics = action.payload;
      state.error = {};
    },
    [getAllTargetAnalytics.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // all neg-target analytics

    [getAllNegativeTargetAnalytics.pending]: (state) => {
      state.loading = true;
    },
    [getAllNegativeTargetAnalytics.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAnalytics = action.payload;
      state.error = {};
    },
    [getAllNegativeTargetAnalytics.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;

    },
    // all search-term analytics

    [getAllSearchTermAnalytics.pending]: (state) => {
      state.loading = true;
    },
    [getAllSearchTermAnalytics.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAnalytics = action.payload;
      state.error = {};
    },
    [getAllSearchTermAnalytics.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      
    },

    // all search-term analytics


    [getAllProductsAnalytics.pending]: (state) => {
      state.loading = true;
    },
    [getAllProductsAnalytics.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAnalytics = action.payload;
      state.error = {};
    },
    [getAllProductsAnalytics.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      
    },
  },
});
export const { clearStaffError } = analyticsSlice.actions;

export default analyticsSlice.reducer;
