import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useLocation } from 'react-router';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useGetToken } from 'src/hooks/useHandleSessions';

import { TOKEN_PREFIX } from 'src/server/api/http';
import logoDarkPng from 'src/assets/logo/logo_dark.png';
import logoWhitePng from 'src/assets/logo/logo_white.png';
import logoSmDarkPng from 'src/assets/logo/logo_sm_dark.png';
import logoSmWhitePng from 'src/assets/logo/logo_sm_white.png';

import Image from '../image';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, theme, color, sx, type, ...other }, ref) => {
  const settings = useSettingsContext();
  const location = useLocation();
  const { token } = useGetToken(TOKEN_PREFIX);

  const getLogo = () => {
    if (
      location?.pathname === '/home' ||
      location?.pathname === '/landing' ||
      location?.pathname === '/contact' ||
      location?.pathname === '/privacy' ||
      location?.pathname === '/terms'
    ) {
      return logoWhitePng;
    }

    if (settings?.themeMode === 'light') {
      return logoDarkPng;
    }

    return logoWhitePng;
  };

  // if (type === 'web_dark') {
  //   selectedLogo = logoDarkPng;
  // } else {
  //   selectedLogo = logoSmGold;
  // }

  const logoLight = (
    <>
      {settings?.themeLayout === 'mini' ? (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: 35,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx,
          }}
          {...other}
        >
          <Image alt="logo" src={logoSmDarkPng} />
        </Box>
      ) : (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: '45%',
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            ...sx,
          }}
          {...other}
        >
          <Image objectFit="contain" alt="logo" src={getLogo()} />
        </Box>
      )}
    </>
  );

  const logoDark = (
    <>
      {settings?.themeLayout === 'mini' ? (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: '50%',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...sx,
          }}
          {...other}
        >
          <Image alt="logo" src={logoSmWhitePng} />
        </Box>
      ) : (
        <Box
          ref={ref}
          component="div"
          sx={{
            width: 130,
            height: 40,
            display: 'inline-flex',
            ...sx,
          }}
          {...other}
        >
          <Image objectFit="contain" alt="logo" src={logoWhitePng} />
        </Box>
      )}
    </>
  );

  if (disabledLink) {
    return logoLight;
  }

  return (
    <>
      {theme === 'dark' ? (
        <Link
          component={RouterLink}
          // href={paths.public.landing}
          href={token ? paths.dashboard.root : paths.public.about}
          sx={{ display: 'contents' }}
        >
          {logoDark}
        </Link>
      ) : (
        <Link
          component={RouterLink}
          // href={paths.public.landing}
          href={token ? paths.dashboard.root : paths.public.about}
          sx={{ display: 'contents' }}
        >
          {settings.themeMode === 'light' ? logoLight : logoDark}
        </Link>
      )}
    </>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  color: PropTypes.any,
  theme: PropTypes.any,
  type: PropTypes.any,
};

export default Logo;
