import { createAsyncThunk } from '@reduxjs/toolkit';

import { get, post } from './http';
import { activeSnack } from '../store/common';

export const getAllCampaign = createAsyncThunk(
  'campaign/list',
  async ({ page, limit, search, dispatch }) => {
    try {
      const response = await get(`/campaign/advertiser/all?search=${search || ''}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCampaignsList = createAsyncThunk(
  'campaign/list',
  async ({ campaignCredentials, query }) => {
    try {
      const URL = `campaign/advertiser/analytics?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;
      const response = await post(URL, campaignCredentials);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getCampaignsDetails = createAsyncThunk(
  'campaign/details',
  async (campaignId, dispatch) => {
    try {
      const URL = `campaign/advertiser/single/${campaignId}`;
      const response = await get(URL);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllAdGroupListFromCampaign = createAsyncThunk(
  'campaign/adGroup/list',
  async ({ campaignCredentials, query }) => {
    try {
      const URL = `ad-group/advertiser/analytics?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;
      const response = await post(URL, campaignCredentials);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSearchTermFromCampaign = createAsyncThunk(
  'campaign/search-term/list',
  async ({ searchTermCredentials, query }) => {
    try {
      const URL = `search-term/advertiser/analytics?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;
      const response = await post(URL, searchTermCredentials);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllNegativeTargetFromCampaign = createAsyncThunk(
  'campaign/negative-target/list',
  async ({ negativeTargetCredentials, query }) => {
    try {
      const URL = `negative-targeting-clause/advertiser/analytics?page=${query?.page || 1}&limit=${
        query?.limit || 25
      }&from=${query?.date?.from || ''}&to=${query?.date?.to || ''}`;
      const response = await post(URL, negativeTargetCredentials);
      if (response) {
        return response;
      }
      return query?.dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      query?.dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
