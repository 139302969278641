import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

// eslint-disable-next-line import/no-cycle
import { useSetRole, useSetToken } from 'src/hooks/useHandleSessions';

import { activeSnack } from '../store/common';
import { get, put, post, contactPost } from './http';

export const forgotPassword = createAsyncThunk(
  'auth/forgot',
  async ({ state, enqueueSnackbar, handleRoles }) => {
    try {
      const response = await post(`/forgot`, state);
      if (response) {
        enqueueSnackbar('Email Sent Successfully!');
        if (handleRoles) {
          localStorage.setItem('userRole', handleRoles());
        }
        useSetToken(response?.token);
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const updatePassword = createAsyncThunk(
  'auth/password/update',
  async ({ state, enqueueSnackbar, token, navigate, userType }) => {
    try {
      const response = await post(`password/reset/${token}`, state);

      if (response) {
        enqueueSnackbar('Password Updated Successfully!');
        useSetToken(response?.token);
        if (userType === 'member') {
          navigate(paths.auth.login_member);
        } else {
          navigate(paths.auth.login);
        }
        // navigate(paths?.auth?.login);

        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const LoginApi = createAsyncThunk('auth/login', async ({ state, dispatch, navigate }) => {
  try {
    const response = await post('user/login', state);

    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated' }));
      useSetToken(response?.token);
      useSetRole(response?.role);
      navigate(paths?.dashboard?.root);
      console.log('response=> ', response);

      return response;
    }

    navigate(paths?.auth?.login_otp_member, { replace: true });
    dispatch(
      activeSnack({
        type: 'success',
        message: 'An OTP has been successfully sent to your registered email.',
      })
    );
    return response;
  } catch (error) {
    console.log('error=>', error);
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const logoutApi = createAsyncThunk('auth/logout', async ({ dispatch, navigate }) => {
  try {
    const response = await get('/logout');
    if (response) {
      sessionStorage.clear();
      dispatch(activeSnack({ type: 'success', message: 'logged out successfully' }));
      navigate(paths.public.landing, { replace: true });
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    sessionStorage.clear();
    navigate(paths.public.landing, { replace: true });
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const memberRegisterApi = createAsyncThunk(
  'auth/register',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/user/register', state);

      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Successfully Registered' }));
        // navigate(paths?.auth?.login_otp_member);
        navigate(`${paths?.auth?.onBoarding}`);

        console.log(response);
        useSetToken(response?.token);
        useSetRole(response?.role);
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const CheckUserName = createAsyncThunk('check/username', async ({ state }) => {
  try {
    const URL = `/check/username`;
    const response = await post(URL, state);
    if (response) {
      // dispatch(activeSnack({ type: 'success', message: response.message }));
      return response;
    }
    return false;
  } catch (error) {
    throw error?.response?.data?.message;
  }
});

export const verifyOtpApi = createAsyncThunk(
  'auth/otp',
  async ({ state, dispatch, handleNextStep, navigate, handleClose, enqueueSnackbar }) => {
    try {
      const response = await post('/verify/otp/', state);
      console.log(response);
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        if (enqueueSnackbar) {
          enqueueSnackbar(' OTP Verified Successfully!');
        }
        if (handleNextStep) {
          return handleNextStep();
        }

        if (handleClose) {
          handleClose();
        }
        if (navigate) {
          navigate(paths?.dashboard?.root, { replace: true });
        }

        // handleNextStep();
        return response;
      }
      // dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getMemberProfile = createAsyncThunk(
  'user/profile/member',
  async ({ enqueueSnackbar, dispatch }) => {
    try {
      const response = await get(`/user/profile`);
      if (response?._id) {
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getAdvertiserProfile = createAsyncThunk(
  'user/profile',
  async ({ enqueueSnackbar }) => {
    try {
      const response = await get(`/advertising-account/advertiser/profile`);
      if (response?.advertiserAccountKey) {
        return response;
      }

      console.log(response);
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      console.log('error =>', error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const updateMemberProfile = createAsyncThunk(
  'user/profile/update',
  async ({ enqueueSnackbar, navigate, state }) => {
    try {
      const response = await put(`/user/profile/update`, state);
      if (response?._id) {
        enqueueSnackbar(' Profile Updated Successfully', state);
        navigate(paths?.auth?.OnBoarding2);

        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// member login
export const memberLoginApi = createAsyncThunk(
  'auth/student/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('member/login', state);
      dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        navigate(paths.dashboard?.root, { replace: true });
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const ResentOtpApi = createAsyncThunk(
  'resend/otp',

  async ({ state, dispatch, handleNextStep, navigate }) => {
    try {
      const response = await post('/resend/otp', state);
      console.log(response);
      if (response) {
        // dispatch(
        //   activeSnack({
        //     type: 'success',
        //     message: '"An OTP has been successfully sent to your registered email.. ',
        //   })
        // );
        if (dispatch) {
          dispatch(
            activeSnack({
              type: 'success',
              message: '"An OTP has been successfully sent to your registered email.. ',
            })
          );
        }
        if (navigate) {
          navigate();
        }
        // handleNextStep();
        return response;
      }
      // dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const EnquiryApi = createAsyncThunk('/enquiry/new', async ({ state, dispatch, reset }) => {
  try {
    const response = await post('/enquiry/new', state);

    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Successfully Submitted' }));
      if (reset) {
        return reset();
      }
      return response;
    }

    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const ContactApi = createAsyncThunk(
  '/contact/create',
  async ({ state, dispatch, reset }) => {
    try {
      const response = await contactPost(
        'https://mail-sender.vingb.com/send/edbffb4e-62b1-4a3c-94af-052f53602300',
        state
      );

      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Successfully Submitted' }));
        if (reset) {
          return reset();
        }
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
