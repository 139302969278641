import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getApp = createAsyncThunk('app/list', async ({ dispatch }) => {
  try {
    const response = await get(`/admin/app`);
    if (response.isSuccess) {
      return response?.app;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(
      activeSnack({
        type: 'error',
        message: error?.response?.data?.message || 'something went wrong',
      })
    );
    throw error?.response?.data?.message;
  }
});

export const getDashboardData = createAsyncThunk(
  'static/dashboard/data',
  async ({ enqueueSnackbar,dates }) => {
    try {
      const response = await get(`/analytics/advertiser/summary?from=${dates[0]?.startDate|| ''}&to=${dates[0]?.endDate ||''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getDashboardDataPerformance = createAsyncThunk(
  'static/dashboard/ppcPerformance',
  async ({ enqueueSnackbar,dates }) => {
    try {
      const response = await get(`analytics/advertiser/ppc-performance?from=${dates[0]?.startDate|| ''}&to=${dates[0]?.endDate ||''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getDashboardDataHighestAcos = createAsyncThunk(
  'static/dashboard/highestAcos',
  async ({ enqueueSnackbar,dates }) => {
    try {
      const response = await get(`analytics/advertiser/highest-acos?from=${dates[0]?.startDate|| ''}&to=${dates[0]?.endDate ||''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getDashboardDataHighestSpend = createAsyncThunk(
  'static/dashboard/highestSpend',
  async ({ enqueueSnackbar,dates }) => {
    try {
      const response = await get(`analytics/advertiser/highest-spend?from=${dates[0]?.startDate|| ''}&to=${dates[0]?.endDate ||''}`);
      return response;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

