import { createSlice } from '@reduxjs/toolkit';

import {
  getIncomeChartData,
  getAmazonConnectLink,
  getAmazonDataFetching,
  getDashboardChartData,
  getProgressionGraphData,
  getSidebarItemCountData,
  getPartnerBalanceChartData,
} from '../api/dashboard';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    error: {},
    loading: false,
    chartLoading: false,
    dashboardData: {},
    campaignChart: [],
    incomeChart: [],
    balanceChart: [],
    progressionData: [],
    sidebarCounts: {},
    siteUrl: {},
    fetchAmazonDatas: {},
  },
  reducers: {
    clearDashboardError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getSidebarItemCountData.pending]: (state) => {
      state.loading = true;
    },
    [getSidebarItemCountData.fulfilled]: (state, action) => {
      state.loading = false;
      state.sidebarCounts = action.payload;
      state.error = {};
    },
    [getSidebarItemCountData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getDashboardChartData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getDashboardChartData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.campaignChart = action.payload;
      state.error = {};
    },
    [getDashboardChartData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },
    [getIncomeChartData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getIncomeChartData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.incomeChart = action.payload;
      state.error = {};
    },
    [getIncomeChartData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },

    [getPartnerBalanceChartData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getPartnerBalanceChartData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.balanceChart = action.payload;
      state.error = {};
    },
    [getPartnerBalanceChartData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },

    [getProgressionGraphData.pending]: (state) => {
      state.chartLoading = true;
    },
    [getProgressionGraphData.fulfilled]: (state, action) => {
      state.chartLoading = false;
      state.progressionData = action.payload;
      state.error = {};
    },
    [getProgressionGraphData.rejected]: (state, action) => {
      state.chartLoading = false;
      state.error = action.error;
    },

    [getAmazonConnectLink.pending]: (state) => {
      state.loading = true;
    },
    [getAmazonConnectLink.fulfilled]: (state, action) => {
      state.siteUrl = action.payload;
      state.error = {};
    },
    [getAmazonConnectLink.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAmazonDataFetching.pending]: (state) => {
      state.loading = true;
    },
    [getAmazonDataFetching.fulfilled]: (state, action) => {
      state.fetchAmazonDatas = action.payload;
      state.error = {};
      state.loading = false;
    },
    [getAmazonDataFetching.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export default dashboardSlice.reducer;
