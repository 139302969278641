import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createWallet = createAsyncThunk(
  'wallets/create',
  async ({ state, dispatch, handleClose, handleClear, memberId }) => {
    try {
      const URL = `wallet-address/new/${memberId}`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        dispatch(activeSnack({ type: 'success', message: 'wallet created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const updateWalletDetails = createAsyncThunk(
  'wallets/update',
  async ({ state, walletId, dispatch, navigate }) => {
    try {
      const URL = `/wallet/admin/${walletId}`;

      const response = await put(URL, state);
      if (response) {
        if (navigate) {
          navigate();
        }
        dispatch(activeSnack({ type: 'success', message: 'Wallet updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllWallet = createAsyncThunk(
  'wallets/list',
  async ({ page, search, dispatch, limit, sortBy, sortDirection }) => {
    try {
      const URL = `/wallet/admin/all?page=${page && page}&search=${search && search}&limit=${
        limit && limit
      }&sortBy=${sortBy && sortBy}&sortDirection=${sortDirection && sortDirection}`;

      const URL_DROPDOWN = `/wallet/admin/all`;

      const response = await get(page ? URL : URL_DROPDOWN);
      if (response) {
        return {
          list: response?.wallets,
          total: response?.total,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getWalletDetails = createAsyncThunk(
  'wallets/single',
  async ({ walletId, dispatch }) => {
    try {
      const response = await get(`/wallet/admin/${walletId}/`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const deleteWallet = createAsyncThunk(
  'wallets/delete',
  async ({ walletId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/wallet/admin/walletId/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(activeSnack({ type: 'success', message: 'Wallet deleted Successfully' }));
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllMemberWalletAddress = createAsyncThunk(
  'wallet-address/list',
  async ({ page, limit, search, dispatch }) => {
    try {
      const response = await get(
        `/member/wallet-address?page=${page && page}&limit=${limit || 5}&search=${search && search}`
      );
      console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
