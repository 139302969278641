import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import { Stack, Typography } from '@mui/material';

import { fDate } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function PersonalInfo({ memberDetails }) {
  console.log(memberDetails);
  return (
    <Card sx={{ height: '100%' }}>
      <Typography py={2} pt={4} px={3} sx={{ fontSize: 14, fontWeight: 700 }}>
        Personal Details
      </Typography>
      <Stack px={3} pb={2}>
        <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>First Name</Typography>
        <Typography sx={{ fontSize: 14, color: '#212B36' }}>{memberDetails?.name}</Typography>
      </Stack>
      {memberDetails?.date_of_birth && (
        <Stack px={3} pb={2}>
          <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>Date of Birth</Typography>

          <Typography sx={{ fontSize: 14, color: '#212B36' }}>
            {fDate(memberDetails?.date_of_birth)}
          </Typography>
        </Stack>
      )}
      <Stack px={3} pb={2}>
        <Typography sx={{ color: 'text.secondary', fontSize: 12 }}>Email Address</Typography>
        <Typography sx={{ fontSize: 14, color: '#212B36' }}>
          {memberDetails?.user?.email}
        </Typography>
      </Stack>
    </Card>
  );
}

PersonalInfo.propTypes = {
  memberDetails: PropTypes.object,
};

// ----------------------------------------------------------------------
