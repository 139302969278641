import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createWalletAddress,
  deleteWalletAddress,
  getAllWalletAddress,
  getWalletAddressDetails,
  createWalletAddressAdmin,
  deleteWalletAddressAdmin,
  getAllWalletAddressAdmin,
  updateWalletAddressDetails,
  getWalletAddressDetailsAdmin,
  updateWalletAddressDetailsAdmin,
} from '../api/walletAddress';

export const walletAddressSlice = createSlice({
  name: 'wallet address',
  initialState: {
    loading: false,
    error: {},
    walletAddresses: {},
    walletAddressDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllWalletAddress.pending]: (state) => {
      state.loading = true;
    },
    [getAllWalletAddress.fulfilled]: (state, action) => {
      state.loading = false;
      state.walletAddresses = action.payload;
      state.error = {};
    },
    [getAllWalletAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // create  details
    [createWalletAddress.pending]: (state) => {
      state.loading = true;
    },
    // [createWalletAddress.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.walletAddresses;
    //   console.log('json state:', jsonState);
    //   console.log('payload:', action.payload);

    //   const newList = jsonState?.walletAddresses;

    //   const modifiedList = {
    //     ...jsonState,
    //     walletAddresses: Array.isArray(newList) ? [...newList, action.payload] : [action.payload],
    //   };
    //   state.loading = false;
    //   state.walletAddressDetails = action.payload;
    //   state.walletAddresses = modifiedList;
    //   state.error = {};
    // },

    [createWalletAddress.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletAddresses;
      const newCityList = jsonState?.walletAddresses;

      const modifiedCityList = {
        ...jsonState,
        walletAddresses: Array.isArray(newCityList)
          ? [action.payload, ...newCityList]
          : [action.payload],
      };
      state.loading = false;
      state.walletAddressDetails = action.payload;
      state.walletAddresses = modifiedCityList;
      state.error = {};
    },
    [createWalletAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getWalletAddressDetails.pending]: (state) => {
      state.loading = true;
    },
    [getWalletAddressDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.walletAddressDetails = action.payload;
      state.error = {};
    },
    [getWalletAddressDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateWalletAddressDetails.pending]: (state) => {
      state.loading = true;
    },

    // [updateWalletAddressDetails.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.walletAddresses;
    //   console.log('payload', action.payload);
    //   const modifiedList = {
    //     ...jsonState,
    //     walletAddresses: jsonState.walletAddresses?.map((i) =>
    //       i._id === action.payload._id ? action?.payload : i
    //     ),
    //   };

    //   state.loading = false;
    //   // state.walletAddresses = modifiedList;
    //   state.walletAddressDetails = action.payload;
    //   state.walletAddresses = modifiedList;
    //   state.error = {};
    // },

    [updateWalletAddressDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletAddresses;
      const modifiedList = {
        ...jsonState,
        walletAddresses: jsonState?.walletAddresses.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };
      state.loading = false;
      state.walletAddressDetails = action.payload;
      state.walletAddresses = modifiedList;
      state.error = {};
    },
    [updateWalletAddressDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteWalletAddress.pending]: (state) => {
      state.loading = true;
    },
    // [deleteWalletAddress.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.walletAddresses;
    //   const modifiedList = {
    //     ...jsonState,
    //     walletAddresses: jsonState.walletAddresses?.filter(
    //       (schoolDivisions) => schoolDivisions._id !== action.payload
    //     ),
    //   };

    //   state.loading = false;
    //   state.walletAddresses = modifiedList;
    //   state.error = {};
    // },
    [deleteWalletAddress.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletAddresses;
      console.log('jason', jsonState);
      console.log('payload', action.payload);
      const modifiedList = {
        ...jsonState,
        walletAddresses: jsonState.walletAddresses?.filter(
          (walletAddress) => walletAddress._id !== action.payload
        ),
      };

      state.loading = false;
      state.walletAddresses = modifiedList;
      state.error = {};
    },
    [deleteWalletAddress.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // admin wallet Address

    [getAllWalletAddressAdmin.pending]: (state) => {
      state.loading = true;
    },
    [getAllWalletAddressAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.walletAddresses = action.payload;
      state.error = {};
    },
    [getAllWalletAddressAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // create  details
    [createWalletAddressAdmin.pending]: (state) => {
      state.loading = true;
    },
    [createWalletAddressAdmin.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletAddresses;
      console.log(jsonState);
      console.log(action.payload);
      const newList = jsonState?.wallet_address;
      console.log(newList);
      const modifiedList = {
        ...jsonState,
        walletAddresses: newList ? [action?.payload, ...newList] : [action.payload],
      };

      console.log(modifiedList);
      state.loading = false;
      state.walletAddresses.wallet_address = modifiedList?.walletAddresses;
      state.error = {};
    },
    [createWalletAddressAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getWalletAddressDetailsAdmin.pending]: (state) => {
      state.loading = true;
    },
    [getWalletAddressDetailsAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      state.walletAddressDetails = action.payload;
      state.error = {};
    },
    [getWalletAddressDetailsAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateWalletAddressDetailsAdmin.pending]: (state) => {
      state.loading = true;
    },

    [updateWalletAddressDetailsAdmin.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletAddresses;
      console.log(jsonState);
      const updateNewList = jsonState?.wallet_address;
      const modifiedList = {
        ...updateNewList,
        walletAddresses: updateNewList?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };
      console.log(modifiedList);
      state.loading = false;
      // state.walletAddresses = modifiedList;
      state.walletAddressDetails = action.payload;
      state.walletAddresses.wallet_address = modifiedList?.walletAddresses;
      state.error = {};
    },

    [updateWalletAddressDetailsAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteWalletAddressAdmin.pending]: (state) => {
      state.loading = true;
    },
    // [deleteWalletAddressAdmin.fulfilled]: (state, action) => {
    //   const jsonState = covertToJSON(state)?.walletAddresses;
    //   const modifiedList = {
    //     ...jsonState,
    //     walletAddresses: jsonState.walletAddresses?.filter(
    //       (schoolDivisions) => schoolDivisions._id !== action.payload
    //     ),
    //   };

    //   state.loading = false;
    //   state.walletAddresses = modifiedList;
    //   state.error = {};
    // },
    [deleteWalletAddressAdmin.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.walletAddresses;

      console.log(jsonState);
      console.log(action.payload);
      const modifiedList = {
        ...jsonState,
        walletAddresses: jsonState.wallet_address?.filter(
          (walletAddresses) => walletAddresses._id !== action.payload
        ),
      };
      state.loading = false;
      console.log(modifiedList);
      state.walletAddresses.wallet_address = modifiedList?.walletAddresses;
      state.error = {};
    },
    [deleteWalletAddressAdmin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = walletAddressSlice.actions;

export default walletAddressSlice.reducer;
