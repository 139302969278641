import {  useEffect } from 'react';

import Box from '@mui/material/Box';

import { useMockedUser } from 'src/hooks/use-mocked-user';

import { hideScroll } from 'src/theme/css';

import Logo from 'src/components/logo';
import { NavSectionMini } from 'src/components/nav-section';
import { useSettingsContext } from 'src/components/settings';

import { NAV } from '../config-layout';
import { useNavData } from './sidebar';
import NavToggleButton from '../common/nav-toggle-button';

// ----------------------------------------------------------------------

export default function NavMini() {
  const { user } = useMockedUser();

const navData = useNavData();
  const settings = useSettingsContext();
  // const stackRef = useRef(null);

  const handleMouseLeave = (event) => {
    event.stopPropagation(); // Prevent event bubbling
    console.log('Mouse left');
    if (settings.themeLayout !== 'mini') {
      settings.onUpdate('themeLayout', 'mini');
    }
  };

  const handleMouseEnter = () => {
    console.log('Mouse entered');
    if (settings.themeLayout !== 'vertical') {
      settings.onUpdate('themeLayout', 'vertical');
    }
  };

  useEffect(() => {
    console.log('themeLayout changed to:', settings.themeLayout);
  }, [settings.themeLayout]);

  return (
    <Box
      className="sidebarMini"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
        // '&:hover': {
        //   backgroundColor: 'red',
        // },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 28,
        }}
      />

      <Box
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          // width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
          width: 60,
          transition: 'width 0.3s ease',
          '&:hover': {
            // Define the styles for the hover state
          },
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={
          handleMouseLeave
        }
      >
        <Logo sx={{ mx: 2, my: 2 }} />

        <NavSectionMini
          data={navData}
          slotProps={{
            currentRole: user?.role,
          }}
        />
      </Box>
    </Box>
  );
}
