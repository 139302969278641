import { createSlice } from '@reduxjs/toolkit';

import { getAllTarget, getAllAdGroup, getAllProducts, getAllPortfolio, getAllSearchTerm } from '../api/dropDownLists';



export const dropDownListReducerSlicer = createSlice({
  name: 'dropDownListReducer',
  initialState: {
    loading: false,
    error: {},
    allPortfolio: {},
    allAdGroups: {},
    allTargets:{},
    allProducts:{},
    allSearchTerm:{}
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [getAllPortfolio.pending]: (state) => {
      state.loading = true;
    },
    [getAllPortfolio.fulfilled]: (state, action) => {
      state.loading = false;
      state.allPortfolio = action?.payload;
      state.error = {};
    },
    [getAllPortfolio.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllAdGroup.pending]: (state) => {
      state.loading = true;
    },
    [getAllAdGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.allAdGroups = action.payload;
      state.error = {};
    },
    [getAllAdGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllTarget.pending]: (state) => {
      state.loading = true;
    },
    [getAllTarget.fulfilled]: (state, action) => {
      state.loading = false;
      state.allTargets = action.payload;
      state.error = {};
    },
    [getAllTarget.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllSearchTerm.pending]: (state) => {
      state.loading = true;
    },
    [getAllSearchTerm.fulfilled]: (state, action) => {
      state.loading = false;
      state.allSearchTerm = action.payload;
      state.error = {};
    },
    [getAllSearchTerm.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [getAllProducts.pending]: (state) => {
      state.loading = true;
    },
    [getAllProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.allProducts = action.payload;
      state.error = {};
    },
    [getAllProducts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
   
  },
});
export const { clearStaffError } = dropDownListReducerSlicer.actions;

export default dropDownListReducerSlicer.reducer;
