import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

import onBoadingImg1 from 'src/assets/illustrations/onBoarding/onBoarding.png';
import onBoadingImg2 from 'src/assets/illustrations/onBoarding/onBoarding2.png';
import connectImagePng from 'src/assets/illustrations/onBoarding/regionSelect.png';

import { SplashScreen } from 'src/components/loading-screen';

const CampaignDetails = lazy(() => import('src/sections/ad-group/SingleView/CampaignDetails'));
const CampaignCreate = lazy(() => import('src/sections/ad-group/crud'));
const AuthClassicOnBoardingLayoutRedirect = lazy(
  () => import('src/layouts/auth/onBoardingLayoutRedirect')
);
const LoginAdmin = lazy(() => import('src/assets/illustrations/login-img.png')); // Note: Images typically aren't lazy-loaded this way
const MyContactPage = lazy(() => import('src/pages/Common/website/NewContact'));
const PrivacyAndPolicy = lazy(() => import('src/pages/Common/website/privicy'));
const AuthClassicOnBoardingLayout = lazy(() => import('src/layouts/auth/onBoardingLayout'));
const AuthClassicOnBoardingLayout2 = lazy(() => import('src/layouts/auth/onBoardingLayout2'));
const AllClub = lazy(() => import('src/pages/Common/Club'));
const AllMembers = lazy(() => import('src/pages/Common/Member'));
const SettingsPage = lazy(() => import('src/pages/Admin/settings'));
const AllAdManager = lazy(() => import('src/pages/Common/AdManager'));
const RegisterPage = lazy(() => import('src/pages/Common/Auth/Register'));
const MemberView = lazy(() => import('src/pages/Common/Member/SingleView'));
const AllAnalytics = lazy(() => import('src/pages/Common/Analytics/index'));

const MemberCrud = lazy(() => import('src/sections/Member/MemberCrud'));
const Redirect = lazy(() => import('src/sections/auth/Onboarding/Redirect'));
const OnBoarding1 = lazy(() => import('src/sections/auth/Onboarding/Onboarding1'));
const OnBoarding2 = lazy(() => import('src/sections/auth/Onboarding/Onboarding2'));
const OnBoardingConnect = lazy(() => import('src/sections/auth/Onboarding/OnBoardingConnect'));
const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LogoutPage = lazy(() => import('src/pages/Common/Auth/LogoutPage'));
const LoginMemberPage = lazy(() => import('src/pages/Common/Auth/MemberLogin'));
const LoginMemberOTPPage = lazy(() => import('src/pages/Common/Auth/MemberOTPLogin'));

const NewPasswordPage = lazy(() => import('src/pages/Common/Auth/NewPassword'));
const ForgotPasswordPage = lazy(() => import('src/pages/Common/Auth/ForgotPassword'));
const AuthModernCompactLayout = lazy(() => import('src/layouts/auth/modern-compact'));
const RegisterLayout = lazy(() => import('src/layouts/auth/registerLayoutNew'));

const CreatePassword = lazy(() => import('src/sections/auth/new-password-view'));

const LandingPage = lazy(() => import('src/pages/Common/LandingWeb/LandingPage'));
const CompactLayout = lazy(() => import('src/layouts/compact'));
const TransactionPage = lazy(() => import('src/pages/Common/Transaction/index'));
const IncomePage = lazy(() => import('src/pages/Common/Income/index'));
const WithdrawalRequestPage = lazy(() => import('src/pages/Common/withdrawalRequest/index'));
const PlanPage = lazy(() => import('src/pages/Common/website/plan'));
const AboutPage = lazy(() => import('src/pages/Common/website/landing'));
const TermsAndConditionPage = lazy(() => import('src/pages/Common/website/terms&conditions'));

const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [
      { path: 'landing', element: <LandingPage /> },
      { path: 'plan', element: <PlanPage /> },
      { path: 'home', element: <AboutPage /> },
      { path: 'contact', element: <MyContactPage /> },
      { path: 'terms', element: <TermsAndConditionPage /> },
      { path: 'privacy', element: <PrivacyAndPolicy /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];

const authRoutes = [
  {
    path: '',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'login',
        element: <Outlet />,
        children: [
          // {
          //   path: 'admin',
          //   element: (
          //     <AuthLayout
          //       welcomeText="Hi, Welcome back"
          //       image={LoginAdmin}
          //       title="Authenticate as an administrator"
          //       userRole="admin"
          //     >
          //       <LoginAdminPage role={AuthRoles.admin} />
          //     </AuthLayout>
          //   ),
          // },
          {
            path: '',
            element: (
              <AuthLayout
                image={LoginAdmin}
                welcomeText=""
                title="Authenticate as an Member"
                userRole="member"
              >
                <LoginMemberPage role={AuthRoles.member} />
              </AuthLayout>
            ),
          },
          {
            path: 'otp',
            element: (
              <AuthLayout image={LoginAdmin} title="Authenticate as an Member" userRole="member">
                <LoginMemberOTPPage role={AuthRoles.member} />
              </AuthLayout>
            ),
          },
        ],
      },

      {
        path: 'logout',
        element: <LogoutPage />,
      },
      {
        path: 'forgot/password',
        element: (
          <AuthModernCompactLayout>
            <ForgotPasswordPage />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create/password/:type/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="create" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'password/reset/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="reset" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create/password/:id',
        element: (
          <AuthModernCompactLayout>
            <CreatePassword />
          </AuthModernCompactLayout>
        ),
      },
      {
        path: 'register',
        element: (
          <RegisterLayout welcomeText="">
            <RegisterPage />
          </RegisterLayout>
        ),
      },
      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },

  {
    path: 'onBoarding',
    element: (
      // <ReferralPage />
      <AuthClassicOnBoardingLayout image={onBoadingImg1}>
        <OnBoarding1 role={AuthRoles.admin} />
      </AuthClassicOnBoardingLayout>
    ),
  },
  {
    path: 'onBoarding/2',
    element: (
      // <ReferralPage />
      <AuthClassicOnBoardingLayout2 image={onBoadingImg2}>
        <OnBoarding2 role={AuthRoles.admin} />
      </AuthClassicOnBoardingLayout2>
    ),
  },
  {
    path: 'onBoarding/connect',
    element: (
      // <ReferralPage />
      <AuthClassicOnBoardingLayout2 image={connectImagePng}>
        <OnBoardingConnect role={AuthRoles.admin} />
      </AuthClassicOnBoardingLayout2>
    ),
  },
  {
    path: 'redirect',
    element: (
      // <ReferralPage />
      <AuthClassicOnBoardingLayoutRedirect>
        <Redirect />
      </AuthClassicOnBoardingLayoutRedirect>
    ),
  },
];

// super admin
const adminRoutes = [
  // {
  //   path: 'dashboard',
  //   element: <AdminWidgetSummary />,
  // },

  {
    path: 'member',
    element: <AllMembers />,
  },
  {
    path: 'member/:id',
    element: <MemberView />,
  },
  {
    path: 'member/create',
    element: <MemberCrud />,
  },
  {
    path: 'withdrawal/requests',
    element: <WithdrawalRequestPage />,
  },
  {
    path: 'clubs',
    element: <AllClub />,
  },
  {
    path: 'transactions',
    element: <TransactionPage />,
  },
  {
    path: 'settings',
    element: <SettingsPage />,
  },
];
// super admin
const memberRoutes = [
  {
    path: 'analytics',
    element: <AllAnalytics />,
  },
  {
    path: 'ad-manager',
    element: <AllAdManager />,
  },
  {
    path: 'ad-manager/create',
    element: <CampaignCreate />,
  },
  {
    path: 'ad-manager/detail/:id',
    element: <CampaignDetails />,
  },
  {
    path: 'income',
    element: <IncomePage />,
  },
  {
    path: 'withdrawal/requests',
    element: <WithdrawalRequestPage />,
  },
  {
    path: 'transactions',
    element: <TransactionPage />,
  },
];

export { authRoutes, adminRoutes, publicRoutes, memberRoutes };
