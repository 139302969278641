import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactCountryFlag from 'react-country-flag';
import { Controller, useFormContext } from 'react-hook-form';

import { CardMedia } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import amznImg from 'src/assets/other/ai.png';
import percentageImg from 'src/assets/other/percentage.png';
// ----------------------------------------------------------------------

export default function RHFTextField({
  name,
  helperText,
  multiline,
  rows,
  showimg,
  showimg2,
  label,
  type,
  data,
  percentage,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      defaultValue="rSHI"
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          variant="outlined"
          label={label}
          value={data && data.accountInfo && data.accountInfo.name}
          multiline={multiline} // Allow multiline
          rows={rows}
          defaultValue={data && data.accountInfo && data.accountInfo.name}
          error={!!error}
          helperText={error ? error.message : helperText}
          InputProps={{
            startAdornment: (
              <>
                {showimg && (
                  <InputAdornment position="start">
                    <CardMedia
                      component="img"
                      src={amznImg}
                      height="25px"
                      width="100%"
                      sx={{ objectFit: 'contain' }}
                    />
                  </InputAdornment>
                )}
                {showimg2 && (
                  <InputAdornment position="start">
                    <ReactCountryFlag countryCode={data?.countryCode} />
                  </InputAdornment>
                )}
              </>
            ),

            endAdornment: (
              <>
                {percentage && (
                  <InputAdornment position="end">
                    <CardMedia
                      component="img"
                      src={percentageImg}
                      height="50px"
                      width="100%"
                      sx={{ objectFit: 'contain' }}
                    />
                  </InputAdornment>
                )}
              </>
            ),
          }}
          {...other}
        />
      )}
    />
  );
}

RHFTextField.propTypes = {
  helperText: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  multiline: PropTypes.bool, // Add prop types for multiline and rows
  rows: PropTypes.number,
  showimg: PropTypes.bool,
  showimg2: PropTypes.bool,
  percentage: PropTypes.bool,
  data: PropTypes.object,
};
