import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { Grid, Stack, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { USDT } from 'src/hooks/use-USDT';

import { fShortenNumber } from 'src/utils/format-number';

// ----------------------------------------------------------------------

export default function MemberCards({ title, club, name, MemberProfileDetails }) {
  console.log(MemberProfileDetails);
  return (
    <Stack>
      {name === 'memberId' && (
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
            pl: 3,
            minHeight: 100,
          }}
        >
          <Box flexDirection="row">
            {/* <Box sx={{ mb: 1, typography: 'h4' }}>{fShortenNumber(total)}</Box> */}
            <Typography variant="h6" sx={{}}>
              {title}
            </Typography>
            <Typography textAlign="center" variant="body2" color="#637381">
              Member ID
            </Typography>
          </Box>
        </Card>
      )}
      {name === 'club' && (
        <Card
          sx={{
            p: 2,
            pl: 3,
            minHeight: 100,
          }}
        >
          <Box flexDirection="row">
            {/* <Box sx={{ mb: 1, typography: 'h4' }}>{fShortenNumber(total)}</Box> */}
            <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
              Current Club
            </Typography>
            {title === true ? (
              <>
                <Grid
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="150%"
                >
                  <Typography sx={{ color: 'text.secondary' }} fontSize="12px">
                    {club?.club?.title} (Active Member)
                  </Typography>
                  <Typography>
                    <CheckCircleRoundedIcon fontSize="small" color="success" />
                  </Typography>
                  <br />
                </Grid>
                <Stack>
                  <Typography textAlign="" variant="body2" color="#637381" fontSize="12px">
                    You can only withdraw $100 USDT
                  </Typography>
                </Stack>
              </>
            ) : (
              <Typography sx={{ color: 'text.secondary' }} fontSize="12px">
                No Active Club
              </Typography>
            )}
          </Box>
        </Card>
      )}

      {name === 'wallet' && (
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            p: 2,
            pl: 3,
            minHeight: 100,
          }}
        >
          <Box flexDirection="row">
            {/* <Box sx={{ mb: 1, typography: 'h4' }}>{fShortenNumber(total)}</Box> */}
            <Typography variant="subtitle2">Wallet Balance</Typography>
            <Typography textAlign="start" variant="h5" color="#004B50">
              {/* {title?.toFixed(2) || 0} {USDT} */}
              {`${fShortenNumber(title?.toFixed(2) || 0)} ${USDT}`}
            </Typography>
          </Box>
        </Card>
      )}
    </Stack>
  );
}

MemberCards.propTypes = {
  title: PropTypes?.object,
  name: PropTypes?.string,
  club: PropTypes?.object,
  MemberProfileDetails: PropTypes?.any,
};
